import React from "react";
import backButton from "../../../Assets/Learn/Videos/backButton.svg";
import { useNavigate } from "react-router-dom";
import Footer from "../../Footer";
const QuickAccessSignUpLogin = () => {
    const navigate = useNavigate();
    const handleNavigation = () => {
        navigate(`/learn?tab=${"Videos"}`);
    };
    return (
        <React.Fragment>
            <div className="learn-main-container">
                <div className="learn-section-container">
                    <div className="learn-section-container1-title">
                        <p>Learn Videos</p>
                    </div>
                    <div className="learn-section-container1-back-navigation-div" onClick={() => { handleNavigation() }}>
                        <img src={backButton} alt="back" />
                        <p>Back to Videos List</p>
                    </div>
                    <div className="learn-section-container1-video">
                        <iframe
                            src="https://www.youtube.com/embed/VoJ_pcJyHeM?si=hSqOgyFK68LsF-iH"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullScreen="allowFullScreen"
                        ></iframe>
                    </div>
                    <div className="learn-section-container1-description">
                        <h3>Quick Access: Sign Up & Login | Best Budget App</h3>
                        <p>
                            Welcome to Best Budget!
                            <br />
                            In this video, we’ll guide you through everything from downloading
                            and installing the app to signing up and logging into the Best
                            Budget app—your ultimate personal finance companion.
                        </p>
                        <p>
                            Start by visiting www.bestbudget.in to get the app. Tap “Start Free
                            Trial” to be redirected to the Play Store, where you can easily
                            download and install the app on your device. Once installed, open
                            the app to begin your financial journey.
                        </p>
                        <p>
                            We’ll walk you through setting up your account, signing up, and
                            logging in with ease. Whether you’re new to Best Budget or returning
                            to take charge of your finances, we’ll make sure you’re ready to
                            dive into features like budget planning, expense tracking, and more.
                        </p>
                        <p>
                            Take the first step toward smarter money management today! Download
                            the app now from{" "}
                            <a href="https://play.google.com/store/apps/details?id=com.crexin.bestbudget&hl=en"
                                target="_blank" rel="noreferrer">Play Store</a> and take
                            the first step toward smarter budgeting.<br /> For any questions, reach
                            out to us at <a href="mailto:support@bestbudget.in">support@bestbudget.in</a>
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
};

export default QuickAccessSignUpLogin;
