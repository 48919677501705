import React from "react";
import "./ChangeBalance.css";
import ChangeAccBal1 from "../../../Assets/Learn/ChangeAccBal1.webp";
import ChangeAccBal2 from "../../../Assets/Learn/ChangeAccBal2.webp";
import ChangeAccBal3 from "../../../Assets/Learn/ChangeAccBal3.webp";
const ChangeBalance = (props) => {
  return (
    <div className="change-balance-main-container">
      <div className="change-balance-breadcrumb">
        <p>Accounts</p>
        <p> &gt; </p>
        <p>Change Balance</p>
      </div>
      <div className="change-balance-title-div">
        <p>How to change my account balance in Best Budget</p>
      </div>
      <div className="change-balance-section-container1">
        <div className="change-balance-section-container1-description">
          <ol>
            <li>
              Navigate to the <span>'Accounts'</span> tab and locate the specific account you
              want to update and tap on the Account field (where the account
              name and details are displayed) to open the Account details page.
            </li>
            <div className="change-balance-section-container1-img1-div">
              <img src={ChangeAccBal1} alt="" />
            </div>
            <li>
              On the Account Details page, look for the <span>Menu</span> icon in the top
              right corner (represented by three horizontal dots) and Tap on the
              <span>Menu</span> icon to reveal additional options.
            </li>
            <li>
              From the drop-down menu, select <span>Edit Account</span>. This will open the
              editing interface where you can make changes to the account.
            </li>
            <div className="change-balance-section-container1-img1-div">
              <img src={ChangeAccBal2} alt="" />
            </div>
            <li>
              On the Edit Account screen, locate the field for the account
              balance. Adjust the balance as needed to reflect your current
              account amount. You can enter a new value to update the account’s
              balance and tap on <span>'Save'</span>.
              <br /> <strong>Tip:</strong> Ensure that the balance update
              aligns with your actual financial records to maintain accurate
              budgeting.
            </li>
            <div className="change-balance-section-container1-img2-div">
              <img src={ChangeAccBal3} alt="" />
            </div>
          </ol>
        </div>
      </div>

      <div className="learn-add-account-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default ChangeBalance;
