import React, { useRef, useEffect } from "react";
import "./LandingWebsite.css";
import video from "../Assets/videos/mobileVideo.mp4";
import dashboardIcon from "../Assets/LandingWebsite/dashboardIcon.svg";
import homeIcon from "../Assets/LandingWebsite/homeIcon.svg";
import briefcaseIcon from "../Assets/LandingWebsite/briefcaseIcon.svg";
import pieChartIcon from "../Assets/LandingWebsite/pieChartIcon.svg";
import toolsIcon from "../Assets/LandingWebsite/toolsIcon.svg";
import timelapseIcon from "../Assets/LandingWebsite/timelapseIcon.svg";
import fingerPrintIcon from "../Assets/LandingWebsite/fingerPrintIcon.svg";
import dataSecurityIcon from "../Assets/LandingWebsite/dataSecurityIcon.svg";
import stopWatchIcon from "../Assets/LandingWebsite/stopWatchIcon.svg";
import magnifierIcon from "../Assets/LandingWebsite/magnifierIcon.svg";
import dataPrivacyIcon from "../Assets/LandingWebsite/dataPrivacyIcon.svg";
import dataReliabilityIcon from "../Assets/LandingWebsite/dataReliabilityIcon.svg";
import section1FooterImage from "../Assets/LandingWebsite/section1FooterImage.webp";
import womanWithReports from "../Assets/LandingWebsite/womanWithReports.webp";
import manSittingOnChair from "../Assets/LandingWebsite/manSittingOnChair.webp";
import nuclearFamily from "../Assets/LandingWebsite/nuclearFamily.webp";
import Footer from "./Footer";

const LandingWebsite = (props) => {
  const videoRef = useRef(null);
  const isMenuOpen = props.isMenuOpen;
  const setIsMenuOpen = props.setIsMenuOpen;
  useEffect(() => {
    videoRef.current.loop = true;
    videoRef.current.play().catch((error) => {
      console.error("Autoplay failed:", error);
    });
  }, []);

  return (
    <React.Fragment>
      <div className="main-container">
        <div className="first-page">
          <section className="section section-container1">
            <div className="section1-part1">
              <div className="section1-title">Gain control of your money</div>
              <div className="section1-description">
                <p
                // style={{
                //   width: window.innerWidth < 432 ? "100%" : "auto",
                //   height: "auto",
                //   fontFamily: "Catamaran",
                //   fontWeight: "400",
                //   fontStyle: "normal",
                //   fontSize: window.innerWidth < 432 ? "12px" : "20px",
                //   lineHeight: window.innerWidth < 432 ? "18px" : "30px",
                //   letterSpacing: window.innerWidth < 432 ? "0.12px" : "0.2px",
                // }}
                >
                  Best Budget is your ultimate personal finance application,
                  designed to simplify money management and empower you to
                  effortlessly achieve your financial goals using effective
                  budgeting methods.
                </p>
                <p
                // style={{
                //   width: window.innerWidth < 432 ? "100%" : "auto",
                //   height: "auto",
                //   fontFamily: "Catamaran",
                //   fontWeight: "400",
                //   fontOpticalSizing: "auto",
                //   fontStyle: "normal",
                //   fontSize: window.innerWidth < 432 ? "12px" : "20px",
                //   lineHeight: window.innerWidth < 432 ? "18px" : "30px",
                //   letterSpacing: window.innerWidth < 432 ? "0.12px" : "0.2px",
                // }}
                >
                  With intuitive features and user-friendly interface, Best
                  Budget is the best app for tracking daily expenses, create
                  monthly budget planner, monitor investments, and much more.
                </p>
              </div>
              <div className="section1-start-div">
                <button
                  type="button"
                  onClick={() => {
                    props.openExternalLink(
                      "https://play.google.com/store/apps/details?id=com.crexin.bestbudget&hl=en"
                    );
                  }}
                >
                  Start Free Trial
                </button>
              </div>
              <div className="section1-footer-animation">
                <img
                  src={section1FooterImage}
                  alt="A person stepping on a graph with money and coins, indicating financial advancement through Best Budget personal finance application."
                />
              </div>
              <div className="section1-part1-border"></div>
            </div>
            <div className="section1-part2">
              <div className="section1-mobile-animation">
                <video
                  width="100%"
                  height="100%"
                  controls={false}
                  autoPlay={true}
                  ref={videoRef}
                  muted={true}
                >
                  <source src={video} type="video/mp4" />
                </video>
              </div>
              <div className="section1-bottom-tray-shape"></div>
            </div>
          </section>
          <section className="section section-container2">
            <div className="section2-title">All the features you need</div>
            <div className="section2-grid-container">
              <div className="section2-grid-element1">
                <div className="grid-element1-img-container">
                  <img alt="" src={dashboardIcon} className="dashboard-icon" />
                </div>
                <div className="grid-element1-title">
                  <p className="grid-element1-title-text">Dashboard</p>
                </div>
                <div className="grid-element1-description">
                  Get an overview of your budgets, top expenses, top payees and
                  balances at a glance.
                </div>
              </div>
              <div className="section2-grid-element2">
                <div className="grid-element2-img-container">
                  <img alt="" src={homeIcon} className="dashboard-icon" />
                </div>
                <div className="grid-element2-title">
                  <p className="grid-element2-title-text">All Accounts</p>
                </div>
                <div className="grid-element2-description">
                  Effortlessly manage all your accounts in one place.
                </div>
              </div>
              <div className="section2-grid-element3">
                <div className="grid-element3-img-container">
                  <img alt="" src={briefcaseIcon} className="dashboard-icon" />
                </div>
                <div className="grid-element3-title">
                  <p className="grid-element3-title-text">Budgets</p>
                </div>
                <div className="grid-element3-description">
                  A budget is a plan that helps you decide where you want yor
                  money to go.
                </div>
              </div>
              <div className="section2-grid-element4">
                <div className="grid-element4-img-container">
                  <img alt="" src={pieChartIcon} className="dashboard-icon" />
                </div>
                <div className="grid-element4-title">
                  <p className="grid-element4-title-text">Reports</p>
                </div>
                <div className="grid-element4-description">
                  Utilize interactive charts to monitor your financial progress
                  and make well-informed decisions regarding your finances.
                </div>
              </div>
              <div className="section2-grid-element5">
                <div className="grid-element5-img-container">
                  <img alt="" src={stopWatchIcon} className="dashboard-icon" />
                </div>
                <div className="grid-element5-title">
                  <p className="grid-element5-title-text">Recurring Bills</p>
                </div>
                <div className="grid-element5-description">
                  Manage your bills easily with recuring transactions.
                </div>
              </div>
              <div className="section2-grid-element6">
                <div className="grid-element6-img-container">
                  <img alt="" src={magnifierIcon} className="dashboard-icon" />
                </div>
                <div className="grid-element6-title">
                  <p className="grid-element6-title-text">Mortgage Tracking</p>
                </div>
                <div className="grid-element6-description">
                  Everything related to your Debt tracking.
                </div>
              </div>
              <div className="section2-grid-element7">
                <div className="grid-element7-img-container">
                  <img alt="" src={toolsIcon} className="dashboard-icon" />
                </div>
                <div className="grid-element7-title">
                  <p className="grid-element7-title-text">Customisation</p>
                </div>
                <div className="grid-element7-description">
                  It’s made to fit your specific needs and preferences, helping
                  you do things in a way that suits you best.
                </div>
              </div>
              <div className="section2-grid-element8">
                <div className="grid-element8-img-container">
                  <img alt="" src={timelapseIcon} className="dashboard-icon" />
                </div>
                <div className="grid-element8-title">
                  <p className="grid-element8-title-text">Alerts</p>
                </div>
                <div className="grid-element8-description">
                  Alert you about your overspending.
                </div>
              </div>
              <div className="section2-grid-element9">
                <div className="grid-element9-img-container">
                  <img
                    alt=""
                    src={fingerPrintIcon}
                    className="dashboard-icon"
                  />
                </div>
                <div className="grid-element9-title">
                  <p className="grid-element9-title-text">Touch ID</p>
                </div>
                <div className="grid-element9-description">
                  Secure access every time you check-in to BestBudget.
                </div>
              </div>
            </div>
          </section>
        </div>
        <section className="section section-container3">
          <div className="section3-title">Be Productive with your money</div>

          <div className="section3-part1">
            <div className="section3-part1-img-container">
              <img
                src={womanWithReports}
                alt="A woman analyzing graphs and charts on a electronic board, representing financial insights with Best Budget personal finance application."
              />
            </div>
            <div className="section3-part1-text-div">
              <div className="section3-part1-text-div-title">
                <p>Find facts about your finances with the best money app</p>
              </div>
              <div className="section3-part1-text-div-description">
                <p>
                  Our easy-to-understand reports shine a light on where your
                  money comes from and where it goes, which helps you take
                  charge of your finances.
                </p>
              </div>
            </div>
          </div>
          <div className="section3-part2">
            <div className="section3-part2-text-div">
              <div className="section3-part2-text-div-title">
                <p>Be a boss of your money</p>
              </div>
              <div className="section3-part2-text-div-description">
                <p>
                  With Best Budget take charge of your money and make
                  intentional decisions about how you earn, spend, save, and
                  invest.
                </p>
              </div>
            </div>
            <div className="section3-part2-img-container">
              <img
                src={manSittingOnChair}
                alt="A man seated with a purple tree backdrop, showcasing tranquility achieved through bes tbudget personal finance app."
              />
            </div>
          </div>
          <div className="section3-part3">
            <div className="section3-part3-text-div">
              <div className="section3-part3-text-div-title">
                <p>Secure Future for your Family</p>
              </div>
              <div className="section3-part3-text-div-description">
                <p>
                  Knowing you have a plan and some savings gives you financial
                  peace of mind and reduces stress about money.
                </p>
              </div>
            </div>
            <div className="section3-part3-img-container">
              <img
                src={nuclearFamily}
                alt="A happy family walking on a road with a child, representing  that their futire is secured using the Best Budget personal finance application."
              />
            </div>
          </div>
        </section>
        <div className="content-background-container"></div>
        <section className="section section-container4">
          <div className="section4-title">How it Works</div>

          <div className="actual-content">
            <div className="actual-content-box-container">
              <div className="actual-content-box1">
                <div className="actual-content-box1-id">
                  <p>01</p>
                </div>
                <div className="actual-content-box1-title">
                  <p>Signup for free</p>
                </div>
                <div className="actual-content-box1-description">
                  <p>Begin your journey with a 21-day free trial.</p>
                </div>
              </div>
              <div className="actual-content-box2">
                <div className="actual-content-box2-id">
                  <p>02</p>
                </div>
                <div className="actual-content-box2-title">
                  <p>Plan your budget</p>
                </div>
                <div className="actual-content-box2-description">
                  <p>
                    Link your accounts, categorize your expenses, and set your
                    financial goals.
                  </p>
                </div>
              </div>
              <div className="actual-content-box3">
                <div className="actual-content-box3-id">
                  <p>03</p>
                </div>
                <div className="actual-content-box3-title">
                  <p>Track your Money</p>
                </div>
                <div className="actual-content-box3-description">
                  <p>
                    With the help of Dashboard and Reports gain insights about
                    your money.
                  </p>
                </div>
              </div>
              <div className="actual-content-box4">
                <div className="actual-content-box4-id">
                  <p>04</p>
                </div>
                <div className="actual-content-box4-title">
                  <p>Save Money</p>
                </div>
                <div className="actual-content-box4-description">
                  <p>
                    Achieve financial peace of mind with Best Budget and save
                    money effortlessly.
                  </p>
                </div>
              </div>
            </div>
            <div className="section4-part2-container">
              <div className="section4-part2-title">
                <p>your path to financial freedom</p>
              </div>
              <div className="section4-part2-description">
                <p>
                  Begin your journey to financial freedom with our personal
                  finance app. Take control of your finances, set goals, and
                  make informed decisions to secure a prosperous future.
                </p>
              </div>
            </div>
          </div>
        </section>
        <div className="content-background-container2"></div>
        <section className="section section-container5">
          <div className="section5-title">Your data is our Responsibility</div>
          <div className="section5-content-box">
            <div className="section5-content-box1">
              <img alt="" src={dataSecurityIcon} />
              <div className="section5-content-box1-title">Data Security</div>
              <p className="section5-content-box1-description">
                Ensuring the highest standards of security, we prioritize
                safeguarding your data through stringent measures and protocols.
              </p>
            </div>
            <div className="section5-content-box2">
              <img alt="" src={dataPrivacyIcon} />
              <div className="section5-content-box1-title">Data Privacy</div>
              <p className="section5-content-box1-description">
                We ensure confidentiality of your financial data, ensuring it’s
                used only for its intended purpose, giving you peace of mind
                regarding your privacy.
              </p>
            </div>
            <div className="section5-content-box3">
              <img alt="" src={dataReliabilityIcon} />
              <div className="section5-content-box1-title">
                Data Reliability
              </div>
              <p className="section5-content-box1-description">
                We make sure to keep a copy of encrypted data files as backup
                for any foreseen events. So your data is secure.
              </p>
            </div>
          </div>
          <div className="page-top-container">
            <div
              className="go-to-page-top"
              onClick={() => {
                props.scrollToTop();
              }}
            ></div>
          </div>
        </section>
      </div>

      <Footer isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
    </React.Fragment>
  );
};

export default LandingWebsite;
