import React from "react";
import "./StartBudgeting.css";
import budgetSettings1 from "../../Assets/Learn/budgetSettings1.webp";
import budgetSettings2 from "../../Assets/Learn/budgetSettings2.webp";
import AddAccount1 from "../../Assets/Learn/AddAccount1.webp";
import AddAccount2 from "../../Assets/Learn/AddAccount2.webp";
import AddAccount3 from "../../Assets/Learn/AddAccount3.webp";
import AddAccount4 from "../../Assets/Learn/AddAccount4.webp";
import AddAccount5 from "../../Assets/Learn/AddAccount5.webp";
import AddAccount6 from "../../Assets/Learn/AddAccount6.webp";
import AddBudgetCat1 from "../../Assets/Learn/AddBudgetCat1.webp";
import AddBudgetCat2 from "../../Assets/Learn/AddBudgetCat2.webp";
import AddBudgetCat3 from "../../Assets/Learn/AddBudgetCat3.webp";
import AddPayees1 from "../../Assets/Learn/AddPayees1.webp";
import AddPayees2 from "../../Assets/Learn/AddPayees2.webp";
import AddPayees3 from "../../Assets/Learn/AddPayees3.webp";
import AddPayees4 from "../../Assets/Learn/AddPayees4.webp";
import AddTransaction1 from "../../Assets/Learn/AddTransaction1.webp";
import AddTransaction2 from "../../Assets/Learn/AddTransaction2.webp";
import AddTransaction3 from "../../Assets/Learn/AddTransaction3.webp";
import AddTransaction4 from "../../Assets/Learn/AddTransaction4.webp";
import AddTransaction5 from "../../Assets/Learn/AddTransaction5.webp";
import AddTransaction6 from "../../Assets/Learn/AddTransaction6.webp";
import AddTransaction7 from "../../Assets/Learn/AddTransaction7.webp";
import AddTransaction8 from "../../Assets/Learn/AddTransaction8.webp";
import AddTransaction9 from "../../Assets/Learn/AddTransaction9.webp";
import AddTransaction10 from "../../Assets/Learn/AddTransaction10.webp";
import AddTransaction11 from "../../Assets/Learn/AddTransaction11.webp";
import Dashboard1 from "../../Assets/Learn/Dashboard1.webp";
import Dashboard2 from "../../Assets/Learn/Dashboard2.webp";
import Dashboard3 from "../../Assets/Learn/Dashboard3.webp";
import Dashboard4 from "../../Assets/Learn/Dashboard4.webp";
import Dashboard5 from "../../Assets/Learn/Dashboard5.webp";
import Dashboard6 from "../../Assets/Learn/Dashboard6.webp";
import Reports1 from "../../Assets/Learn/Reports1.webp";
import Reports2 from "../../Assets/Learn/Reports2.webp";
import Reports3 from "../../Assets/Learn/Reports3.webp";
import Reports4 from "../../Assets/Learn/Reports4.webp";
import Reports5 from "../../Assets/Learn/Reports5.webp";
const StartBudgeting = (props) => {
  return (
    <div className="start-budgeting-main-container">
      <div className="start-budgeting-breadcrumb">
        <p>Get Started</p>
        <p> &gt; </p>
        <p>Start Budgeting</p>
      </div>
      <div className="start-budgeting-title-div">
        <p>Getting started with Best Budget</p>
      </div>
      <div className="start-budgeting-description">
        <p>
          Take charge of your finances effortlessly with our Budget App,
          designed to simplify budgeting. Start your journey towards financial
          success today with the following steps.
        </p>
        <ol type="1">
          <li>Budget Plan Details</li>
          <li>Add Accounts</li>
          <li>Add Budget Categories</li>
          <li>Allocate Money to Categories</li>
          <li>Add Payees</li>
          <li>Enter Transactions</li>
          <li>Dashboard</li>
          <li>Analyse Reports</li>
        </ol>
      </div>
      <div className="start-budgeting-section-comtainer1">
        <div className="start-budgeting-section-container1-title">
          <p>1. Budget Plan Details</p>
        </div>
        <div className="start-budgeting-section-container1-description">
          <p>
            Best Budget allows you to create and maintain multiple budgets plans
            within a single account for both personal and family needs.
          </p>
          <ol type="a">
            <li>
              Give each budget plan a specific name to easily distinguish
              between them.
            </li>
            <li>
              By default, Best Budget is set to IST standards. But you can
              easily change these settings to match your needs.
            </li>
            <div className="start-budgeting-section-container1-img1-div">
              <img src={budgetSettings1} alt="" />
            </div>
            <div className="start-budgeting-section-container1-img1-div">
              <img src={budgetSettings2} alt="" />
            </div>
          </ol>
        </div>
        <div className="start-budgeting-section-container1-title">
          <p>2. Add Accounts</p>
        </div>
        <div className="start-budgeting-section-container1-description">
          <ol type="a">
            <li>
              Navigate to <span>'Accounts'</span> tab and tap on <span>'Add accounts'</span> icon located
              at the top right.
            </li>
            <div className="start-budgeting-section-container1-img1-div">
              <img src={AddAccount1} alt="" />
            </div>
            <li>
              To add an account, Type the desired account name in the designated
              field.
            </li>
            <div className="start-budgeting-section-container1-img1-div">
              <img src={AddAccount2} alt="" />
            </div>
            <li>
              Select the account type from the provided options. Best Budget
              offers three account types to choose from:
              <br />
              <strong>Budget Accounts:</strong> For managing your day-to-day
              finances, including cash, digital wallets, and bank accounts.
              <br />
              <strong>Non Budget Accounts:</strong> For tracking your long-term
              financial goals, such as retirement savings, stocks, assets,
              liabilities and real estate.
              <br />
              <strong>Loans & Mortgage Accounts:</strong> For keeping track of
              your loans and mortgage payments to stay on top of your debt
              obligations.
            </li>
            <div className="start-budgeting-section-container1-img2-div">
              <img src={AddAccount3} alt="" />
            </div>
            <li>
              Enter the current balance. The toggle button helps to change the
              amount to positive or negative as needed.
            </li>
            <div className="start-budgeting-section-container1-img4-div">
              <img src={AddAccount4} alt="" />
            </div>
            <div className="start-budgeting-section-container1-img4-div">
              <img src={AddAccount5} alt="" />
            </div>
            <li>Enter optional 'Account notes' and tap on <span>'Save'</span> button.</li>
            <div className="start-budgeting-section-container1-img3-div">
              <img src={AddAccount6} alt="" />
            </div>
          </ol>
        </div>
        <div className="start-budgeting-section-container1-title">
          <p>3. Add Budget Categories</p>
        </div>
        <div className="start-budgeting-section-container1-description">
          <ol type="a">
            <li>
              We've pre-categorized your expenses into groups like Needs, Wants,
              and Savings & Investments. Review these categories and make any
              necessary adjustments to suit your specific financial goals.
            </li>
            <li>
              To edit, delete, rename, hide or create new categories and
              category groups, tap on the pencil icon located in the top right
              corner.
            </li>
            <div className="start-budgeting-section-container1-img1-div">
              <img src={AddBudgetCat1} alt="" />
            </div>
            <li>
              Utilize the appropriate icons displayed on the screen to easily
              manage your budget categories and groups. These icons will allow
              you to <span>edit, delete, rename, hide, switch</span> and <span>create</span> new
              categories and category groups.
            </li>
            <div className="start-budgeting-section-container1-img5-div">
              <img src={AddBudgetCat2} alt="" />
            </div>
          </ol>
        </div>
        <div className="start-budgeting-section-container1-title">
          <p>4. Allocate Money to Categories</p>
        </div>
        <div className="start-budgeting-section-container1-description">
          <ol type="a">
            <li>
              Assign a specific budget amount to each category to effectively
              manage your finances. Best Budget promotes a realistic approach to
              budgeting, ensuring that your spending aligns with your income and
              goals.
            </li>
            <li>
              To allocate or modify the allocated amount for each budget
              category, Tap on the <span>'Allocated'</span> section. This will allow you to
              easily adjust your spending limits and ensure that your budget
              aligns with your financial goals.
            </li>
            <div className="start-budgeting-section-container1-img1-div">
              <img src={AddBudgetCat3} alt="" />
            </div>
          </ol>
        </div>
        <div className="start-budgeting-section-container1-title">
          <p>5. Add Payees</p>
        </div>
        <div className="start-budgeting-section-container1-description">
          <ol type="a">
            <li>
              A payee is any person or entity that you send or receive money
              from. To view and manage your payees, go to the <span>'Payees'</span> section
              by selecting <span>'More'</span> from the bottom navigation.
            </li>
            <div className="start-budgeting-section-container1-img4-div">
              <img src={AddPayees1} alt="" />
            </div>
            <div className="start-budgeting-section-container1-img4-div">
              <img src={AddPayees2} alt="" />
            </div>
            <li>
              Simplify Payee management by tapping <span>'Add Payee'</span> icon located at
              the top right corner. Enter the payee's name and customize their
              profile by setting default categories and payment direction. This
              will streamline your budgeting and transaction tracking process.
            </li>
            <div className="start-budgeting-section-container1-img1-div">
              <img src={AddPayees3} alt="" />
            </div>
            <div className="start-budgeting-section-container1-img1-div">
              <img src={AddPayees4} alt="" />
            </div>
          </ol>
        </div>
        <div className="start-budgeting-section-container1-title">
          <p>6. Enter Transactions</p>
        </div>
        <div className="start-budgeting-section-container1-description">
          <p>
            To ensure accurate balance tracking in Best Budget, record all your
            income, expense, or transfer transactions on a regular basis. This
            will help you maintain a comprehensive overview of your financial
            situation.
          </p>
          <ol type="a">
            <li>Tap on the <span>'Transaction'</span> icon from the bottom Navigation.</li>
            <div className="start-budgeting-section-container1-img1-div">
              <img src={AddTransaction1} alt="" />
            </div>
            <li>
              To track your expenses, select expense & enter the expense amount
              you made, by default the transaction will be in pending state, you
              can mark it as cleared, if the transaction is already excuted.
            </li>
            <div className="start-budgeting-section-container1-img1-div">
              <img src={AddTransaction2} alt="" />
            </div>
            <li>
              Select a payee from your list or create a new one. Then, choose
              the appropriate category and account associated with the
              transaction. Specify the transaction date.
            </li>
            <li>
              You have the flexibility to schedule transactions for future
              processing, allowing you to plan your finances in advance.
            </li>
            <div className="start-budgeting-section-container1-img3-div">
              <img src={AddTransaction3} alt="" />
            </div>
            <div className="start-budgeting-section-container1-img1-div">
              <img src={AddTransaction4} alt="" />
            </div>
            <li>
              You can also set reminders for your scheduled transactions. These
              reminders will notify you before the transaction is processed,
              ensuring you’re always aware of your upcoming financial
              activities.
              <br />
              <strong>Note:</strong> This field will appear only when you set
              recurring frequency.
            </li>
            <div className="start-budgeting-section-container1-img3-div">
              <img src={AddTransaction5} alt="" />
            </div>
            <div className="start-budgeting-section-container1-img1-div">
              <img src={AddTransaction6} alt="" />
            </div>
            <li>
              Finally, tap on the Save button to record the expense transaction.
            </li>
            <div className="start-budgeting-section-container1-img3-div">
              <img src={AddTransaction7} alt="" />
            </div>
            <li>
              Select <span>'Income'</span> to add a transaction where you received money.
            </li>
            <div className="start-budgeting-section-container1-img1-div">
              <img src={AddTransaction8} alt="" />
            </div>
            <li>
              Similarly, for income transaction, repeat the same process as
              expense.
            </li>
            <div className="start-budgeting-section-container1-img3-div">
              <img src={AddTransaction9} alt="" />
            </div>
            <li>
              While saving an income transaction, if a specific category is
              selected other than <span>"Available to Budget"</span>, <span>"Best Budget"</span> will
              recommend assigning the amount to Available to Budget for general
              use. However, you have the option to either follow this
              recommendation or assign the income directly to a specified
              category, based on your preference. This flexibility allows you to
              decide how best to allocate your funds.
            </li>
            <div className="start-budgeting-section-container1-img3-div">
              <img src={AddTransaction10} alt="" />
            </div>
            <li>
              If there is a transfer between the accounts which you hold just
              tap non transfer and mention from account and to account between
              whom the money is transferred. The category here will be defaulted
              to NA since there is no money flowing in or out. Give the date for
              this transaction and memo and select the frequency under recurring
              and give the repeat frequency and set remainder if required.
            </li>
            <div className="start-budgeting-section-container1-img3-div">
              <img src={AddTransaction11} alt="" />
            </div>
          </ol>
        </div>
        <div className="start-budgeting-section-container1-title">
          <p>7. Dashboard </p>
        </div>
        <div className="start-budgeting-section-container1-description">
          <p>
            The Best Budget dashboard provides a comprehensive and
            easy-to-understand overview of your finances, allowing you to
            quickly assess your financial health and make informed decisions.
          </p>
          <ol type="a">
            <li>
              The Best Budget dashboard is divided into four sections:<span> Summary,
                Allocations, Top Spenders,</span> and <span>Upcoming</span>. Use the right and left
              arrows to navigate between these sections and gain a comprehensive
              overview of your finances.
            </li>
            <li>
              The <span>'Summary'</span> section provides essential insights into your
              current financial situation, including total allocations, spending
              to date, and remaining budget. This information helps you
              understand your financial progress and make informed decisions.
            </li>
            <div className="start-budgeting-section-container1-img3-div">
              <img src={Dashboard1} alt="" />
            </div>
            <li>
              The Budget <span>'Allocations'</span> section provides a visual representation
              of your spending across different categories. This allows you to
              easily identify areas where you may need to adjust your budget.
            </li>
            <li>
              Tap on the desired category group to view the allocations for each
              category within that group.
            </li>
            <div className="start-budgeting-section-container1-img3-div">
              <img src={Dashboard2} alt="" />
            </div>
            <li>
              Use the right and left arrows to switch between categories of
              different category groups.
            </li>
            <div className="start-budgeting-section-container1-img2-div">
              <img src={Dashboard3} alt="" />
            </div>
            <li>
              To return to the view showing all category groups, tap the <span>'Back' </span>
              icon.
            </li>
            <div className="start-budgeting-section-container1-img2-div">
              <img src={Dashboard4} alt="" />
            </div>
            <li>
              Gain a clear understanding of your spending habits by reviewing
              the <span>'Top Spenders'</span> section. This section identifies your biggest
              expense categories and payees, allowing you to make informed
              decisions about your finances.
            </li>
            <div className="start-budgeting-section-container1-img3-div">
              <img src={Dashboard5} alt="" />
            </div>
            <li>
              The <span>'Upcoming'</span> section of the Best Budget dashboard provides a
              comprehensive list of all your scheduled transactions, allowing
              you to stay on top of your bills and payments. This helps you
              avoid late fees and ensure that your finances are in order.
            </li>
            <div className="start-budgeting-section-container1-img3-div">
              <img src={Dashboard6} alt="" />
            </div>
          </ol>
        </div>
        <div className="start-budgeting-section-container1-title">
          <p>8. Analyse Reports </p>
        </div>
        <div className="start-budgeting-section-container1-description">
          <ol type="a">
            <p>
              To understand your spending patterns in detail, explore the <span>Reports </span>
              tab. This tab offers comprehensive reports on your spending by
              category and category groups, helping you make informed financial
              decisions.
            </p>
            <div className="start-budgeting-section-container1-img4-div">
              <img src={AddPayees1} alt="" />
            </div>
            <div className="start-budgeting-section-container1-img4-div">
              <img src={Reports1} alt="" />
            </div>

            <li>
              The Reports tab in Best Budget is divided into two sections:
              <span> Spending Analysis</span> and <span>Net Worth</span>.
            </li>
            <li>
              The <span>'Spending Analysis'</span> tab provides a detailed breakdown of your
              spending habits across categories.
            </li>
            <div className="start-budgeting-section-container1-img3-div">
              <img src={Reports2} alt="" />
            </div>
            <li>
              Use the right and left arrows to switch between categories of
              different category groups.
            </li>
            <div className="start-budgeting-section-container1-img3-div">
              <img src={Reports3} alt="" />
            </div>
            <li>
              To return to the view showing all category groups, tap the <span>'Back' </span>
              icon.
            </li>
            <div className="start-budgeting-section-container1-img3-div">
              <img src={Reports4} alt="" />
            </div>
            <li>
              The <span>'Net Worth'</span> tab offers a powerful insight into your overall
              financial health by calculating your total wealth.
            </li>
            <div className="start-budgeting-section-container1-img3-div">
              <img src={Reports5} alt="" />
            </div>
          </ol>
        </div>
      </div>
      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default StartBudgeting;
