import React from "react";
import "./MakeTransfer.css";
import IncomeTrans1 from "../../../Assets/Learn/IncomeTrans1.webp";
import IncomeTrans15 from "../../../Assets/Learn/IncomeTrans15.webp";
import TransferTransaction2 from "../../../Assets/Learn/TransferTransaction2.webp";
import TransferTransaction3 from "../../../Assets/Learn/TransferTransaction3.webp";
import TransferTransaction4 from "../../../Assets/Learn/TransferTransaction4.webp";
import TransferTransaction5 from "../../../Assets/Learn/TransferTransaction5.webp";
import TransferTransaction6 from "../../../Assets/Learn/TransferTransaction6.webp";
import TransferTransaction7 from "../../../Assets/Learn/TransferTransaction7.webp";
import TransferTransaction8 from "../../../Assets/Learn/TransferTransaction8.webp";
import TransferTransaction9 from "../../../Assets/Learn/TransferTransaction9.webp";
import TransferTransaction10 from "../../../Assets/Learn/TransferTransaction10.webp";
import TransferTransaction11 from "../../../Assets/Learn/TransferTransaction11.webp";
import TransferTransaction12 from "../../../Assets/Learn/TransferTransaction12.webp";
import TransferTransaction13 from "../../../Assets/Learn/TransferTransaction13.webp";
import TransferTransaction14 from "../../../Assets/Learn/TransferTransaction14.webp";
import TransferTransaction15 from "../../../Assets/Learn/TransferTransaction15.webp";
import TransferTransaction16 from "../../../Assets/Learn/TransferTransaction16.webp";
import TransferTransaction17 from "../../../Assets/Learn/TransferTransaction17.webp";
const MakeTransfer = (props) => {
  return (
    <div className="make-transfer-main-container">
      <div className="make-transfer-breadcrumb">
        <p>Transactions</p>
        <p> &gt; </p>
        <p>Make a Transfer</p>
      </div>
      <div className="make-transfer-title-div">
        <p>How to add a transfer transaction in Best Budget</p>
      </div>
      <div className="make-transfer-section-container1">
        <div className="make-transfer-section-container1-description">
          <ol>
            <li>
              Tap on the <span>'Transaction'</span> icon located in the navigation bar at the
              bottom of the screen. This will open the transaction creation page
              where you can add your new transaction.
            </li>
            <div className="make-transfer-section-container1-img1-div">
              <img src={IncomeTrans1} alt="" />
            </div>
            <li>
              By default, the <span>'Expense'</span> transaction type will be selected. If
              you wish to create an Transfer transaction, tap the <span>'Transfer' </span>
              button to switch from Expense to Transfer.
            </li>
            <div className="make-transfer-section-container1-img2-div">
              <img src={TransferTransaction2} alt="" />
            </div>
            <li>
              In the <span>Transaction Amount</span> field, input the amount. This is the
              amount that will be recorded for this transaction.
            </li>
            <div className="make-transfer-section-container1-img2-div">
              <img src={TransferTransaction3} alt="" />
            </div>
            <li>
              Tap on the <span>'From Account'</span> field to choose the account from which
              the funds will be transferred. This could be your checking
              account, savings account, or any other account that you wish to
              transfer from.
            </li>
            <div className="make-transfer-section-container1-img2-div">
              <img src={TransferTransaction4} alt="" />
            </div>
            <div className="make-transfer-section-container1-img2-div">
              <img src={TransferTransaction5} alt="" />
            </div>
            <li>
              Tap on the <span>'To Account'</span> field to choose the account where the
              funds will be transferred to.
            </li>
            <div className="make-transfer-section-container1-img2-div">
              <img src={TransferTransaction6} alt="" />
            </div>
            <div className="make-transfer-section-container1-img2-div">
              <img src={TransferTransaction7} alt="" />
            </div>
            <p>
              <strong>Note:</strong>
              <br />
              <ol type="a">
                <li>
                  In transfer transactions, a category is required only when
                  transferring originates from or to a budget account.
                </li>
                <li>
                  For transfers between two Budget accounts, no category is
                  needed—the Category field will automatically populate with
                  <span> 'Category NA'</span> and cannot be edited. This helps keep transfer
                  categorisation clear and accurate across different account
                  types.
                </li>
              </ol>
            </p>
            <div className="make-transfer-section-container1-img2-div">
              <img src={TransferTransaction8} alt="" />
            </div>
            <div className="make-transfer-section-container1-img2-div">
              <img src={TransferTransaction9} alt="" />
            </div>
            <li>
              By default, today’s date will be pre-filled in the <span>Date</span> field. You
              can change it by tapping on the field and selecting the desired
              date for the transfer.
            </li>
            <div className="make-transfer-section-container1-img2-div">
              <img src={TransferTransaction10} alt="" />
            </div>
            <div className="make-transfer-section-container1-img3-div">
              <img src={TransferTransaction11} alt="" />
            </div>
            <li>
              Tap on the <span>Memo</span> field to enter any notes regarding the transfer.
              This is optional but can be helpful for tracking the reason for
              the transfer.
            </li>
            <div className="make-transfer-section-container1-img2-div">
              <img src={TransferTransaction12} alt="" />
            </div>
            <li>
              You have the flexibility to schedule transactions for future
              processing, allowing you to plan your finances in advance.
            </li>
            <div className="make-transfer-section-container1-img2-div">
              <img src={TransferTransaction13} alt="" />
            </div>
            <div className="make-transfer-section-container1-img2-div">
              <img src={IncomeTrans15} alt="" />
            </div>
            <li>You can also set reminders for your scheduled transactions. These reminders will notify you before the transaction is processed, ensuring you’re always aware of your upcoming financial activities.
              <br /><strong>Note:</strong> This field will appear only when you set recurring frequency.</li>
            <div className="make-transfer-section-container1-img2-div">
              <img src={TransferTransaction14} alt="" />
            </div>
            <div className="make-transfer-section-container1-img2-div">
              <img src={TransferTransaction15} alt="" />
            </div>
            <li>
              Once all the details have been filled out, tap the <span>'Save'</span> button
              located at the bottom of the screen to finalize and save the
              transaction.
            </li>

            <div className="make-transfer-section-container1-img2-div">
              <img src={TransferTransaction16} alt="" />
            </div>
            <div className="make-transfer-section-container1-img2-div">
              <img src={TransferTransaction17} alt="" />
            </div>
            <p>
              <strong>Note:</strong> When creating a new transaction, the app will automatically
              pre-fill the details from the previous transaction (if any) based
              on the transaction type (Income or Expense or Transfer) you are
              creating. You can edit these details as necessary before saving.
              This feature helps streamline the process when dealing with
              similar transactions.
            </p>
          </ol>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default MakeTransfer;
