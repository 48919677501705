import React, { useState, useEffect, useCallback } from "react";
import "./BestBudgetWay.css";
import weatherStarsIcon from "../Assets/BestBudgetWay/weatherStarsIcon.svg";
import timeLapseIcon from "../Assets/BestBudgetWay/timeLapseIcon.svg";
import calendarIcon from "../Assets/BestBudgetWay/calendarIcon.svg";
import checkSquareIcon from "../Assets/BestBudgetWay/checkSquareIcon.svg";
import coupleSittingOnBench from "../Assets/BestBudgetWay/coupleSittingOnBench.svg";
import girlWatchingSunset from "../Assets/BestBudgetWay/girlWatchingSunset.svg";
import womanSittingOnChair from "../Assets/BestBudgetWay/womanSittingOnChair.webp";
import nextSlideIcon from "../Assets/BestBudgetWay/nextSlideIcon.svg";
import prevSlideIcon from "../Assets/BestBudgetWay/prevSlideIcon.svg";
import Footer from "./Footer";
import firstPageMobileScreen from "../Assets/BestBudgetWay/firstPageMobileScreen.webp";
import allocateAllCategoriesImage from "../Assets/BestBudgetWay/allocateAllCategoriesImage.webp";
import bulkActionImage from "../Assets/BestBudgetWay/bulkActionImage.webp";
import insightsImage from "../Assets/BestBudgetWay/insightsImage.webp";
import summaryImage from "../Assets/BestBudgetWay/summaryImage.webp";
import scheduledImage from "../Assets/BestBudgetWay/scheduledImage.png";
import budgetScreenImage from "../Assets/BestBudgetWay/budgetScreenImage.png";
import reportsScreenImage from "../Assets/BestBudgetWay/reportsScreenImage.png";
import dashboardScreenImage from "../Assets/BestBudgetWay/dashboardScreenImage.png";
import SEO from "./SEO";
const BestBudgetWay = (props) => {
  const [section2CurrentSlide, setSection2CurrentSlide] = useState(0);
  const [section4CurrentSlide, setSection4CurrentSlide] = useState(0);
  const sectionTwoSlides = [
    {
      title: (
        <div className="intelligent-budgeting-title">Intelligent Budgeting</div>
      ),
      description: (
        <div className="intelligent-budgeting-description">
          Budget all categories in one go based on your statistics
        </div>
      ),
      imgSrc: "allocate-all-categories-image",
      imgLink: allocateAllCategoriesImage,
      altText: "A Snapshot from Best Budget app displaying the feature of allocation to categories based on statistics."
    },
    {
      title: (
        <div className="bulk-action-title">Bulk actions in Transactions</div>
      ),
      description: (
        <div className="bulk-action-description">
          Multiple transactions can be selected at a time to perform desired
          operation.
        </div>
      ),
      imgSrc: "bulk-action-image",
      imgLink: bulkActionImage,
      altText: "A snapshot from Best Budget app displaying how to perform bulk actions on transactions"
    },
    {
      title: <div className="insights-title">Insights that matter for you</div>,
      description: (
        <div className="insights-description">
          Meaningful Insight and suggestions to boost your financial well-being.
        </div>
      ),
      imgSrc: "insights-image",
      imgLink: insightsImage,
      altText: "Image of Best Budget personal finance app's budget tab with various insights displayed."
    },
    {
      title: <div className="summary-title">Simple yet powerful Summary</div>,

      description: (
        <div className="summary-description">
          Consolidated view of your allocations, spending, and balance in one place for a comprehensive financial snapshot.
        </div>
      ),
      imgSrc: "summary-image",
      imgLink: summaryImage,
      altText: "A summary of Key figures data from the Best Budget personal finance application."
    },
  ];
  const sectionFourSlides = [
    {
      title: <p className="simple-budgeting-title">Simple Budgeting</p>,
      description: (
        <p className="simple-budgeting-description">
          Simple and Clutter free Budgeting. Quick summary of current and
          previous months budget and spending gives you an overview
        </p>
      ),
      imgSrc: "section-container4-upper-layer-container1-image",
      imgLink: budgetScreenImage,
      altText: "A screenshot showing a budget page with  list of categories and their allocation amounts in Best Budget personal finance app.",
    },
    {
      title: (
        <p className="knowledge-at-your-fingertips-title">
          Knowledge at your fingertips
        </p>
      ),
      description: (
        <p className="knowledge-at-your-fingertips-description">
          Gain insights into your spending habits with detailed reports and
          visualizations
        </p>
      ),
      imgSrc: "section-container4-upper-layer-container3-image",
      imgLink: reportsScreenImage,
      altText: "Monthly spending analysis by category groups in Best Budget personal finance app dashboard.",
    },
    {
      title: (
        <p className="everything-at-a-glance-title">Everything at a Glance</p>
      ),
      description: (
        <p className="everything-at-a-glance-description">
          Intuitive dashboard gives you a quick summary of your Balance, Budgets
          and Spending.
        </p>
      ),
      imgSrc: "section-container4-upper-layer-container5-image",
      imgLink: dashboardScreenImage,
      altText: "Dashboard in Best Budget app displaying monthly data summary,",
    },
  ];

  const nextSection2Slide = useCallback(() => {
    setSection2CurrentSlide(
      (prevSlide) => (prevSlide + 1) % sectionTwoSlides.length
    );
  }, [sectionTwoSlides.length])

  const prevSection2Slide = () => {
    setSection2CurrentSlide(
      (prevSlide) =>
        (prevSlide - 1 + sectionTwoSlides.length) % sectionTwoSlides.length
    );
  };
  const nextSection4Slide = useCallback(() => {
    setSection4CurrentSlide(
      (prevSlide) => (prevSlide + 1) % sectionFourSlides.length
    );
  }, [sectionFourSlides.length])

  const prevSection4Slide = () => {
    setSection4CurrentSlide(
      (prevSlide) =>
        (prevSlide - 1 + sectionFourSlides.length) % sectionFourSlides.length
    );
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      nextSection2Slide();
    }, 5000); // Change slide every 2.5 seconds (adjust as needed)

    return () => {
      clearInterval(intervalId);
    };
  }, [nextSection2Slide]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      nextSection4Slide();
    }, 2500); // Change slide every 2.5 seconds (adjust as needed)

    return () => {
      clearInterval(intervalId);
    };
  }, [nextSection4Slide]);

  return (
    <React.Fragment>
      <SEO
        title="Why BestBudget"
        description="Experience the Best-in class design, features and comprehensive support with Best Budget. We have thoughtfully designed and developed this application to make it intuitive and ingenious"
      />
      <div className="main-container1">
        <div className="bbw-section-container1">
          <div className="bbw-section1-container1-bottom-layer"></div>
          <div className="bbw-section1-container1-upper-layer">
            <div className="bbw-section1-mobile-div">
              <div className="bbw-section1-mobile-div-img">
                <img src={firstPageMobileScreen} alt="A mobile phone displaying the Best Budget app, featuring the accounts page." />
              </div>
            </div>
            <div className="bbw-section1-part2">
              <div className="section1-title-container">
                <div className="section1-title-div">
                  Experience best-in-class
                </div>
                <div className="section1-title-decoration">
                  <img alt="" src={weatherStarsIcon} className="weather-star1" />

                  <img alt="" src={weatherStarsIcon} className="weather-star2" />
                </div>
              </div>
              <div className="section1-description-container">
                <div className="section1-part2-bottom-layer">
                  <div className="section1-part2-bottom-layer-img"></div>
                </div>
                <div className="section1-description-text">
                  <p>
                    Experience the Best-in class design, features and
                    comprehensive support with Best Budget.
                  </p>
                  <p>
                    We have thoughtfully designed and developed this application
                    to make it intuitive and ingenious
                  </p>
                  <div className="start-your-free-trial2" onClick={() => {
                    props.openExternalLink("https://play.google.com/store/apps/details?id=com.crexin.bestbudget&hl=en");
                  }}>
                    <p>Start Free Trial</p>
                  </div>
                </div>
              </div>
              <div className="section-footer-image">
                <img src={womanSittingOnChair} alt="A woman sitting on a chair with a cup of coffee, using the Best Budget personal finance application on her mobile." />
              </div>
            </div>
          </div>
        </div>
        <div className="bbw-section-container2">
          <div className="section2-title-div">
            <p>Do more with less effort</p>
          </div>
          {window.innerWidth <= 440 ? (
            <div className="bbw-section2-carousal-container">
              <div
                className="slide-btn"
                onClick={() => {
                  prevSection2Slide();
                }}
              >
                <img alt="" src={prevSlideIcon} />
              </div>
              <div className="bbw-section2-carousal">
                {sectionTwoSlides &&
                  sectionTwoSlides.map((slide, index) => {
                    return (
                      <div
                        key={index}
                        className={`section2-slide ${index === section2CurrentSlide ? "active" : ""
                          }`}
                      >
                        <div className={slide.imgSrc}>
                          <img src={slide.imgLink} alt={slide.altText} />
                        </div>
                        <div>{slide.title}</div>

                        <div>{slide.description}</div>
                      </div>
                    );
                  })}
                <div className="section2-radio-controls">
                  {sectionTwoSlides &&
                    sectionTwoSlides.map((_, index) => {
                      return (
                        <div
                          key={index}
                          className={`section2-radio-container ${index === section2CurrentSlide ? "checked" : ""
                            }`}
                          onClick={() => setSection2CurrentSlide(index)}
                        ></div>
                      );
                    })}
                </div>
              </div>
              <div
                className="slide-btn"
                onClick={() => {
                  nextSection2Slide();
                }}
              >
                <img alt="" src={nextSlideIcon} />
              </div>
            </div>
          ) : (
            <div></div>
          )}

          <div className="section2-first-cotainer">
            <div className="intelligent-budgeting">
              <div className="allocate-all-categories">
                <img src={allocateAllCategoriesImage} alt="A Snapshot from Best Budget app displaying the feature of allocation to categories based on statistics." />
              </div>
              <div className="intelligent-budgeting-title">
                Intelligent Budgeting
              </div>
              <div className="intelligent-budgeting-description">
                Budget all categories in one go based on your statistics
              </div>
            </div>
            <div className="bulk-actions-transactions">
              <div className="bulk-action-image">
                <img src={bulkActionImage} alt="A snapshot from Best Budget app displaying how to perform bulk actions on transactions" />
              </div>
              <div className="bulk-action-title">
                Bulk actions in Transactions
              </div>
              <div className="bulk-action-description">
                Multiple transactions can be selected at a time to perform
                desired operation.
              </div>
            </div>
          </div>

          <div className="section2-center-image"></div>
          <div className="section2-second-cotainer">
            <div className="insights">
              <div className="insights-image">
                <img src={insightsImage} alt="Best Budget personal finance app's budget tab with various insights displayed." />
              </div>
              <div className="insights-title">Insights that matter for you</div>
              <div className="insights-description">
                Meaningful Insight and suggestions to boost your financial
                well-being.
              </div>
            </div>
            <div className="summary">
              <div className="summary-image">
                <img src={summaryImage} alt="A summary of Key figures data from the Best Budget personal finance application." />
              </div>
              <div className="summary-title">Simple yet powerful Summary</div>
              <div className="summary-description">
                Consolidated view of your allocations, spending, and balance in one place for a comprehensive financial snapshot.
              </div>
            </div>
          </div>
        </div>
        <div className="bbw-section-container3">
          <div className="section3-title-div">
            <p>Manage your Bills using Recurring Transactions</p>
          </div>
          <div className="section3-content-div">
            <div className="all-bills-at-your-glance">
              <div className="all-bills-at-your-glance-div">
                <div className="all-bills-at-your-glance-image">
                  <img src={scheduledImage} alt="A screenshot from Best Budget personal finance application displaying a list of upcoming or scheduled  transactions." />
                </div>
                <div className="all-bills-at-your-glance-title">
                  <p>All of your Bills at a glance</p>
                </div>
                <div className="all-bills-at-your-glance-description">
                  <p>
                    See all your Recurring transactions in one place so you
                    don’t miss out paying them on time
                  </p>
                </div>
              </div>
              <div className="man-holding-card-div"></div>
            </div>
            <div className="section3-part2-list">
              <div className="mark-as-recurring">
                <img alt="" src={timeLapseIcon} />
                <p className="mark-as-recurring-title">Mark as Recurring</p>
                <p className="mark-as-recurring-description">
                  Add new recurring Transactions to Best Budget.
                </p>
              </div>
              <div className="set-frequency">
                <img alt="" src={calendarIcon} />
                <p className="set-frequency-title">Set frequency</p>
                <p className="set-frequency-description">
                  Adjust the recurring frequency as you want.
                </p>
              </div>
              <div className="approve-delete">
                <img alt="" src={checkSquareIcon} />
                <p className="approve-delete-title">Approve or delete</p>
                <p className="approve-delete-description">
                  Approve or delete your recurring transactions
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bbw-section-container4">
          <div className="section-container4-bottom-layer">
            <div className="section4-title-div">
              <p>Increase your Financial Productivity</p>
            </div>
            <div className="section4-first-image-container">
              <div className="couple-sitting-on-bench">
                <img alt="A couple using Best Budget app, sitting on a park bench, calculating their savings and expenses." src={coupleSittingOnBench} />
              </div>
            </div>
            <div className="section4-text-container">
              <div className="simple-budgeting">
                <p className="simple-budgeting-title">Simple Budgeting</p>
                <p className="simple-budgeting-description">
                  Simple and Clutter free Budgeting. Quick summary of current
                  and previous months budget and spending gives you an overview
                </p>
              </div>
              <div className="knowledge-at-your-fingertips">
                <p className="knowledge-at-your-fingertips-title">
                  Knowledge at your fingertips
                </p>
                <p className="knowledge-at-your-fingertips-description">
                  Gain insights into your spending habits with detailed reports
                  and visualizations
                </p>
              </div>
              <div className="everything-at-a-glance">
                <p className="everything-at-a-glance-title">
                  Everything at a Glance
                </p>
                <p className="everything-at-a-glance-description">
                  Intuitive dashboard gives you a quick summary of your Balance,
                  Budgets and Spending.
                </p>
              </div>
            </div>
            <div className="section4-second-image-container">
              <div className="girl-watching-sunset">
                <img alt="Woman happily holding a rope in the woods, symbolizing saving money and enjoying time with the Best Budget personal finance app." src={girlWatchingSunset} />
              </div>
            </div>
          </div>
          <div className="section-container4-upper-layer">
            <div class="section-container4-upper-layer-container1">
              <div className="section-container4-upper-layer-container1-image">
                <img src={budgetScreenImage} alt="A screenshot showing a budget page with  list of categories and their allocation amounts in Best Budget personal finance app." />
              </div>
            </div>
            <div class="section-container4-upper-layer-container2"></div>
            <div class="section-container4-upper-layer-container3">
              <div className="section-container4-upper-layer-container3-image">
                <img alt="Monthly spending analysis by category groups in Best Budget personal finance app dashboard." src={reportsScreenImage} />
              </div>
            </div>
            <div class="section-container4-upper-layer-container4"></div>
            <div class="section-container4-upper-layer-container5">
              <div className="section-container4-upper-layer-container5-image">
                <img alt="Dashboard in Best Budget app displaying monthly data summary," src={dashboardScreenImage} />
              </div>
            </div>
          </div>
          <div className="bbw-section4-title-div">
            <p>Increase your Financial Productivity</p>
          </div>
          <div className="bbw-section4-carousal-container">
            <div className="section4-carousal-part1">
              <div
                className="section4-slide-btn"
                onClick={() => {
                  prevSection4Slide();
                }}
              >
                <img alt="" src={prevSlideIcon} />
              </div>
              <div className="bbw-section4-carousal">
                {sectionFourSlides &&
                  sectionFourSlides.map((slide, index) => {
                    return (
                      <div
                        key={index}
                        className={`section4-slide ${index === section4CurrentSlide ? "active-slide" : ""
                          }`}
                      >
                        <div className={slide.imgSrc}>
                          <img src={slide.imgLink} alt={slide.altText} />
                        </div>
                      </div>
                    );
                  })}
                <div className="section4-radio-controls">
                  {sectionFourSlides &&
                    sectionFourSlides.map((_, index) => {
                      return (
                        <div
                          key={index}
                          className={`section4-radio-container ${index === section4CurrentSlide ? "checked" : ""
                            }`}
                          onClick={() => setSection4CurrentSlide(index)}
                        ></div>
                      );
                    })}
                </div>
              </div>
              <div
                className="section4-slide-btn"
                onClick={() => {
                  nextSection4Slide();
                }}
              >
                <img alt="" src={nextSlideIcon} />
              </div>
            </div>
            <div className="section4-carousal-part2">
              {sectionFourSlides.map((slide, index) => {
                return (
                  <div
                    key={index}
                    className={`section4-description ${index === section4CurrentSlide ? "active-description" : ""
                      }`}
                  >
                    <div className="section4-carousal-title">{slide.title}</div>
                    <div className="section4-carousal-description">
                      {slide.description}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="bbw-section4-second-image-container">
            <div className="bbw-girl-watching-sunset">
              <img alt="" src={girlWatchingSunset} />
            </div>
          </div>
        </div>
        <div className="bbw-section-container5">
          <div className="ready-to-get-started-div">
            <p className="ready-to-get-started-title">Ready to Get Started</p>
            <div className="start-your-free-trial" onClick={() => {
              props.openExternalLink("https://play.google.com/store/apps/details?id=com.crexin.bestbudget&hl=en");
            }}>
              <p>Start Free Trial</p>
            </div>
          </div>
          <div className="go-to-page-top-container">
            <div
              className="go-to-page-top"
              onClick={() => {
                props.scrollToTop();
              }}
            ></div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default BestBudgetWay;
