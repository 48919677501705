import React from "react";
import backButton from "../../../Assets/Learn/Videos/backButton.svg";
import { useNavigate } from "react-router-dom";
import Footer from "../../Footer";
const TrackManagePayeesAndTransactions = () => {
    const navigate = useNavigate();
    const handleNavigation = () => {
        navigate(`/learn?tab=${"Videos"}`);
    };
    return (
        <React.Fragment>
            <div className="learn-main-container">
                <div className="learn-section-container">
                    <div className="learn-section-container1-title">
                        <p>Learn Videos</p>
                    </div>
                    <div className="learn-section-container1-back-navigation-div" onClick={() => { handleNavigation() }}>
                        <img src={backButton} alt="back" />
                        <p>Back to Videos List</p>
                    </div>
                    <div className="learn-section-container1-video">
                        <iframe
                            src="https://www.youtube.com/embed/1gklpEzow1w?si=kGtRqpvGWPc7TuT6"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullScreen="allowFullScreen"
                        ></iframe>
                    </div>
                    <div className="learn-section-container1-description">
                        <h3>Track & Manage Payments | Payees & Transactions Made Simple</h3>
                        <p>
                            Welcome to the Best Budget Payees & Transactions guide! In this
                            video, we’ll show you how to set up and manage payees and
                            transactions within your budget. Adding payees like grocery stores,
                            utilities, or subscription services allows you to track where your
                            money is going with precision.
                        </p>
                        <p>
                            You’ll also learn how to add and categorize transactions. Whether
                            it’s a one-time expense or a recurring payment, entering your
                            transactions helps you stay on top of your spending and ensures your
                            budget stays accurate.
                        </p>
                        <p>
                            We’ll also cover how to edit, delete, and categorize transactions
                            and how to keep your budget aligned with your real-life expenses.
                            This way, you can get a detailed breakdown of your financial
                            activity and make smarter decisions.
                        </p>
                        <p>
                            Join us and see how easy it is to manage your finances, track your
                            expenses, and stay in control of your financial journey with Best
                            Budget!
                        </p>
                        <p>
                            Download the app now from{" "}
                            <a href="https://play.google.com/store/apps/details?id=com.crexin.bestbudget&hl=en"
                                target="_blank" rel="noreferrer">Play Store</a> and take
                            the first step toward smarter budgeting.
                            <br /> Need help? Email us at{" "}
                            <a href="mailto:support@bestbudget.in">support@bestbudget.in</a> for
                            assistance.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
};

export default TrackManagePayeesAndTransactions;
