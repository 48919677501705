import React from "react";
import "./DeleteCategory.css";
import DeleteCategory1 from "../../../Assets/Learn/DeleteCategory1.webp";
import DeleteCategory2 from "../../../Assets/Learn/DeleteCategory2.webp";
import DeleteCategory3 from "../../../Assets/Learn/DeleteCategory3.webp";
import DeleteCategory4 from "../../../Assets/Learn/DeleteCategory4.webp";
import DeleteCategory5 from "../../../Assets/Learn/DeleteCategory5.webp";
import DeleteCategory6 from "../../../Assets/Learn/DeleteCategory6.webp";
import DeleteCategory7 from "../../../Assets/Learn/DeleteCategory7.webp";
import DeleteCategory8 from "../../../Assets/Learn/DeleteCategory8.webp";
import DeleteCategory9 from "../../../Assets/Learn/DeleteCategory9.webp";
import DeleteCategory10 from "../../../Assets/Learn/DeleteCategory10.webp";
const DeleteCategory = (props) => {
  return (
    <div className="delete-category-main-container">
      <div className="delete-category-breadcrumb">
        <p>Budget</p>
        <p> &gt; </p>
        <p>Delete Category</p>
      </div>
      <div className="delete-category-title-div">
        <p>How to delete a category in Best Budget</p>
      </div>
      <div className="delete-category-section-container1">
        <div className="delete-category-section-container1-description">
          <ol>
            <li>
              On your Best Budget App, open Budget page and tap on <span>“Manage
                Categories”</span> icon located on the top right corner.
            </li>
            <div className="delete-category-section-container1-img1-div">
              <img src={DeleteCategory1} alt="" />
            </div>
            <li>
              Locate the desired category you wish to delete.
              <br />
              <strong>Note:</strong> A category can only be deleted if it has no
              allocations or past transactions associated with it. If there are
              allocations, you need to transfer the amount to another category
              and then delete it.
            </li>
            <li>
              Tap on the <span>'menu'</span> icon positioned near the right side of the
              category.
            </li>
            <li>Select <span>"Delete"</span> option.</li>
            <div className="delete-category-section-container1-img1-div">
              <img src={DeleteCategory2} alt="" />
            </div>
            <li>
              A pop-up window will appear, select <span>Delete</span> option to continue with
              deletion.
            </li>
            <div className="delete-category-section-container1-img1-div">
              <img src={DeleteCategory3} alt="" />
            </div>
            <div className="delete-category-section-container1-img1-div">
              <img src={DeleteCategory4} alt="" />
            </div>
          </ol>
        </div>
      </div>
      <div className="delete-category-title-div">
        <p>How to delete a category with allocations in Best Budget</p>
      </div>
      <div className="delete-category-section-container1">
        <div className="delete-category-section-container1-description">
          <ol>
            <li>Locate the desired category you wish to delete.</li>
            <li>
              Tap on the <span>'menu'</span> icon positioned near the right side of the
              category.
            </li>
            <li>Select <span>"Delete"</span> option.</li>
            <div className="delete-category-section-container1-img1-div">
              <img src={DeleteCategory5} alt="" />
            </div>
            <li>Select the category you want to transfer the amount.</li>
            <li>
              Alternatively, you can create a new category to transfer the
              amount to, and then delete the original category.
            </li>
            <div className="delete-category-section-container1-img1-div">
              <img src={DeleteCategory6} alt="" />
            </div>
            <div className="delete-category-section-container1-img1-div">
              <img src={DeleteCategory7} alt="" />
            </div>
            <li>Click on <span>'Submit'</span> button to complete deletion.</li>
            <div className="delete-category-section-container1-img1-div">
              <img src={DeleteCategory8} alt="" />
            </div>
            <div className="delete-category-section-container1-img1-div">
              <img src={DeleteCategory9} alt="" />
            </div>
            <div className="delete-category-section-container1-img1-div">
              <img src={DeleteCategory10} alt="" />
            </div>
          </ol>
        </div>
      </div>
      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default DeleteCategory;
