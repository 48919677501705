import React from "react";
import "./AddRecurrTransaction.css";
import IncomeTrans1 from "../../../Assets/Learn/IncomeTrans1.webp";
import IncomeTrans15 from "../../../Assets/Learn/IncomeTrans15.webp";
import RecurrTrans2 from "../../../Assets/Learn/RecurrTrans2.webp";
import RecurrTrans3 from "../../../Assets/Learn/RecurrTrans3.webp";
import RecurrTrans5 from "../../../Assets/Learn/RecurrTrans5.webp";
import RecurrTrans6 from "../../../Assets/Learn/RecurrTrans6.webp";
import RecurrTrans7 from "../../../Assets/Learn/RecurrTrans7.webp";
import RecurrTrans8 from "../../../Assets/Learn/RecurrTrans8.webp";
import RecurrTrans9 from "../../../Assets/Learn/RecurrTrans9.webp";
import RecurrTrans10 from "../../../Assets/Learn/RecurrTrans10.webp";
import RecurrTrans11 from "../../../Assets/Learn/RecurrTrans11.webp";
import RecurrTrans12 from "../../../Assets/Learn/RecurrTrans12.webp";
import RecurrTrans13 from "../../../Assets/Learn/RecurrTrans13.webp";
const AddRecurrTransaction = (props) => {
  return (
    <div className="add-recurr-transaction-main-container">
      <div className="add-recurr-transaction-breadcrumb">
        <p>Transactions</p>
        <p> &gt; </p>
        <p>Add a Recurring Transaction</p>
      </div>
      <div className="add-recurr-transaction-title-div">
        <p>How to add a recurring transaction in Best Budget</p>
      </div>
      <div className="add-recurr-transaction-section-container1">
        <div className="add-recurr-transaction-section-container1-description">
          <ol>
            <li>
              Tap on the <span>'Transaction'</span> icon located in the navigation bar at the
              bottom of the screen. This will open the transaction creation page
              where you can add your new transaction.
            </li>
            <div className="add-recurr-transaction-section-container1-img1-div">
              <img src={IncomeTrans1} alt="" />
            </div>
            <li>
              By default, the expense transaction type will be selected. Select
              the required type of transaction.
            </li>
            <li>
              Enter the necessary data such as Payee, Category, Account, Date
              and memo for the transaction.
            </li>
            <div className="add-recurr-transaction-section-container1-img3-div">
              <img src={RecurrTrans2} alt="" />
            </div>
            <li>
              You have the flexibility to schedule transactions for future
              processing, allowing you to plan your finances in advance. Tap on
              the <span>'Recurring'</span> field to set up a scheduled transaction.
            </li>
            <div className="add-recurr-transaction-section-container1-img2-div">
              <img src={RecurrTrans3} alt="" />
            </div>
            <div className="add-recurr-transaction-section-container1-img2-div">
              <img src={IncomeTrans15} alt="" />
            </div>
            <li>
              You can also set reminders for your scheduled transactions. These
              reminders will notify you before the transaction is processed,
              ensuring you’re always aware of your upcoming financial
              activities.
              <br /> <strong>Note:</strong> This field will appear only when you
              set recurring frequency.
            </li>
            <div className="add-recurr-transaction-section-container1-img3-div">
              <img src={RecurrTrans5} alt="" />
            </div>
            <div className="add-recurr-transaction-section-container1-img2-div">
              <img src={RecurrTrans6} alt="" />
            </div>
            <li>
              Finally, tap on the <span>'Save'</span> button to record the expense
              transaction.
            </li>
            <div className="add-recurr-transaction-section-container1-img3-div">
              <img src={RecurrTrans7} alt="" />
            </div>
          </ol>
        </div>
      </div>
      <div className="add-recurr-transaction-title-div">
        <p>How to open scheduled Transactions</p>
      </div>
      <div className="add-recurr-transaction-section-container1">
        <div className="add-recurr-transaction-section-container1-description">
          <ol>
            <li>
              Navigate to the <span>'All Accounts'</span> Transactions page or open the
              <span> Account details</span> of the specific account that contains the
              scheduled transactions you wish to manage.
            </li>
            <div className="add-recurr-transaction-section-container1-img1-div">
              <img src={RecurrTrans8} alt="" />
            </div>
            <li>
              Tap on the <span>'Upcoming'</span> field to expand and display all scheduled
              transactions.
            </li>
            <div className="add-recurr-transaction-section-container1-img2-div">
              <img src={RecurrTrans9} alt="" />
            </div>
            <div className="add-recurr-transaction-section-container1-img2-div">
              <img src={RecurrTrans10} alt="" />
            </div>
            <li>
              Tap on the desired scheduled transaction to open options for
              editing or deleting it.
            </li>
            <div className="add-recurr-transaction-section-container1-img2-div">
              <img src={RecurrTrans11} alt="" />
            </div>
            <div className="add-recurr-transaction-section-container1-img3-div">
              <img src={RecurrTrans12} alt="" />
            </div>
            <li>
              To delete multiple scheduled transactions at once, click on the
              checkboxes to the left of each transaction you want to remove, and
              proceed with the delete action.
            </li>
            <div className="add-recurr-transaction-section-container1-img3-div">
              <img src={RecurrTrans13} alt="" />
            </div>
          </ol>
        </div>
      </div>
      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default AddRecurrTransaction;
