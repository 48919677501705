import React from 'react';
import { useNavigate } from 'react-router-dom';
import backButton from "../../../Assets/Learn/Videos/backButton.svg";
import Footer from '../../Footer';
const FirstStepsSettingUpBestBudget = () => {
    const navigate = useNavigate();
    const handleNavigation = () => {
        navigate(`/learn?tab=${"Videos"}`);
    };
    return (
        <React.Fragment>
            <div className="learn-main-container">
                <div className="learn-section-container">
                    <div className="learn-section-container1-title">
                        <p>Learn Videos</p>
                    </div>
                    <div className="learn-section-container1-back-navigation-div" onClick={() => { handleNavigation() }}>
                        <img src={backButton} alt="back" />
                        <p>Back to Videos List</p>
                    </div>
                    <div className="learn-section-container1-video">
                        <iframe
                            src="https://www.youtube.com/embed/ZM_GO84HYCE?si=L-Z8wl9ENLN1ljpH"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullScreen="allowFullScreen"
                        ></iframe>
                    </div>
                    <div className="learn-section-container1-description">
                        <h3>Your First Steps | Setting Up Best Budget Like a Pro</h3>
                        <p>
                            Welcome to Best Budget!
                            <br />
                            In this video, we’ll walk you through all the essential steps to take control of your finances using the Best Budget app.
                        </p>
                        <p>
                            You’ll learn how to create a budget plan, set up and manage your accounts, and allocate your income effectively to track your spending. We’ll also guide you through creating payees, adding transactions, and exploring key features like the Dashboard and Reports, which give you valuable insights into your financial health.
                        </p>
                        <p>
                            By the end of this video, you’ll know exactly how to use Best Budget to simplify your money management and stay on top of your financial goals.
                        </p>
                        <p>
                            Ready to dive in? Let’s get started!  Download
                            the app now from{" "}
                            <a href="https://play.google.com/store/apps/details?id=com.crexin.bestbudget&hl=en"
                                target="_blank">Play Store</a> and take
                            the first step toward smarter budgeting.<br /> Need help? Email us at <a href="mailto:support@bestbudget.in">support@bestbudget.in</a> for assistance.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default FirstStepsSettingUpBestBudget