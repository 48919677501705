import React from "react";
import "./FilterTransactions.css";
import Filters1 from "../../../Assets/Learn/Filters1.webp";
import Filters2 from "../../../Assets/Learn/Filters2.webp";
import Filters3 from "../../../Assets/Learn/Filters3.webp";
const FilterTransactions = (props) => {
  return (
    <div className="filter-transaction-main-container">
      <div className="filter-transaction-breadcrumb">
        <p>Transactions</p>
        <p> &gt; </p>
        <p>Filter Transactions</p>
      </div>
      <div className="filter-transaction-title-div">
        <p>How to filter transactions in Best Budget</p>
      </div>
      <div className="filter-transaction-section-container1">
        <div className="filter-transaction-section-container1-description">
          <ol>
            <li>
              Go to the <span>'All Accounts'</span> Transactions page or open
              the transaction details of the desired account.
            </li>
            <li>
              Tap on the <span>'filters'</span> icon (represented by 3
              horizontal dots) located at the top right to open the filters
              menu.
            </li>
            <div className="filter-transaction-section-container1-img1-div">
              <img src={Filters1} alt="" />
            </div>
            <li>
              <spa>Date Filters</spa>: By default, <span>'All Dates'</span>{" "}
              option is selected. If you need to view data for a different time
              period, select another date filter.
            </li>
            <li>
              If Date Range is selected, Tap on the <span>'Start date'</span> to
              choose the beginning date, and then tap on the{" "}
              <span>'End date'</span> to select the ending date to customize the
              range for the spending analysis.
            </li>
            <div className="filter-transaction-section-container1-img2-div">
              <img src={Filters2} alt="" />
            </div>
            <li>
              <sspan>Accounts Filters</sspan>: By default,{" "}
              <span>'Accounts'</span> is opened with <span>'Select All'</span>{" "}
              filters. Tap on <span>'Select None'</span> to unselect all
              accounts or uncheck specific accounts as needed.
            </li>
            <div className="filter-transaction-section-container1-img3-div">
              <img src={Filters3} alt="" />
            </div>
            <li>
              <span>Other Filters</span>: Click on <span>'Payees'</span>,{" "}
              <span>'Categories'</span>, <span>'Status'</span>, or
              <span> 'Transactions'</span> to open their respective filters.
            </li>
            <li>
              If you are satisfied with the selected filters, tap the{" "}
              <span>'Apply'</span> button to activate them and refresh the data
              accordingly.{" "}
            </li>
            <li>
              To clear all selected filters and reset to the default settings,
              tap the <span>'Reset'</span> button.
            </li>
          </ol>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default FilterTransactions;
