import React from "react";
import "./EditCateGroup.css";
import AddCatGroup1 from "../../../Assets/Learn/AddCatGroup1.webp";
import EditCatGroup2 from "../../../Assets/Learn/EditCatGroup2.webp";
import EditCatGroup3 from "../../../Assets/Learn/EditCatGroup3.webp";
import EditCatGroup4 from "../../../Assets/Learn/EditCatGroup4.webp";
const EditCateGroup = (props) => {
  return (
    <div className="edit-cate-group-main-container">
      <div className="edit-cate-group-breadcrumb">
        <p>Budget</p>
        <p> &gt; </p>
        <p>Edit Category Group</p>
      </div>
      <div className="edit-cate-group-title-div">
        <p>How to Edit a Category Group in Best Budget</p>
      </div>
      <div className="edit-cate-group-section-container1">
        <div className="edit-cate-group-section-container1-description">
          <ol>
            <li>
              Tap on the <span>'Manage Categories'</span> icon (represented by a pencil
              shape) located in the top right corner of the screen. This will
              direct you to the category management section.
            </li>
            <div className="edit-cate-group-section-container1-img1-div">
              <img src={AddCatGroup1} alt="" />
            </div>
            <li>
              Locate the category group field you wish to edit. Tap on the field
              to enable editing mode.
            </li>
            <div className="edit-cate-group-section-container1-img1-div">
              <img src={EditCatGroup2} alt="" />
            </div>
            <li>
              Modify the name of the category group as desired to better reflect
              its purpose or contents.
            </li>
            <div className="edit-cate-group-section-container1-img1-div">
              <img src={EditCatGroup3} alt="" />
            </div>
            <li>
              After making your changes, tap on the <span>'SAVE'</span> button to save the
              updated category group name. <br />
              <strong>Note:</strong> You can add new category groups
              and categories, edit existing ones, and save all changes at once
              by tapping the <span>'SAVE'</span> button. This feature allows for efficient
              management of your categories without the need to save each change
              individually.
            </li>
            <div className="edit-cate-group-section-container1-img1-div">
              <img src={EditCatGroup4} alt="" />
            </div>
          </ol>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default EditCateGroup;
