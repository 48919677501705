const environment = {
    baseUrl: "http://localhost:8080",
};

if (process.env.REACT_APP_ENV === 'development') {
    environment.baseUrl = "http://localhost:8080";
}
if (process.env.REACT_APP_ENV === 'staging') {
    environment.baseUrl = "https://dev.bestbudget.in/"
}

if (process.env.REACT_APP_ENV === 'production') {
    environment.baseUrl = "https://bestbudget.in/"
}
export default environment;