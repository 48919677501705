import React from "react";
import { useNavigate } from "react-router-dom";
import backButton from "../../../Assets/Learn/Videos/backButton.svg";
import Footer from "../../Footer";
const ManageBudgetsAndPersonalizeSettings = () => {
    const navigate = useNavigate();
    const handleNavigation = () => {
        navigate(`/learn?tab=${"Videos"}`);
    };
    return (
        <React.Fragment>
            <div className="learn-main-container">
                <div className="learn-section-container">
                    <div className="learn-section-container1-title">
                        <p>Learn Videos</p>
                    </div>
                    <div className="learn-section-container1-back-navigation-div" onClick={() => { handleNavigation() }}>
                        <img src={backButton} alt="back" />
                        <p>Back to Videos List</p>
                    </div>
                    <div className="learn-section-container1-video">
                        <iframe
                            src="https://www.youtube.com/embed/XRpeI0dPhis?si=IFheNIUEOJgjcTcB"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullScreen="allowFullScreen"
                        ></iframe>
                    </div>
                    <div className="learn-section-container1-description">
                        <h3>Manage Budgets & Personalize Settings</h3>
                        <p>
                            Welcome to the Best Budget Multiple Budgets, Profile & Settings
                            guide! In this video, we’ll guide you through managing multiple
                            budgets and setting up your profile preferences in Best Budget,
                            making it easier than ever to stay in control of your finances.
                        </p>
                        <p>
                            Multiple Budgets: Learn how to create new budgets, edit existing
                            ones, switch between budgets, and even delete unused ones. Whether
                            you're managing personal expenses, household finances, or
                            work-related budgets, this feature ensures seamless organization for
                            all your financial goals.
                        </p>
                        <p>
                            Profile Settings: Customize your experience by updating your display
                            name and email address, enabling added security with an MPIN or
                            fingerprint login, and keeping your account secure.
                        </p>
                        <p>
                            Take your budgeting game to the next level by mastering these
                            essential tools. It’s time to simplify your financial journey and
                            make budgeting stress-free with Best Budget!
                        </p>
                        <p>
                            Download the app now from{" "}
                            <a href="https://play.google.com/store/apps/details?id=com.crexin.bestbudget&hl=en"
                                target="_blank" rel="noreferrer">Play Store</a> and take
                            the first step toward smarter budgeting.
                            <br /> Need help? Email us at{" "}
                            <a href="mailto:support@bestbudget.in">support@bestbudget.in</a> for
                            assistance.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
};

export default ManageBudgetsAndPersonalizeSettings;
