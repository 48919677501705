import React from "react";
import "./RearrangeAccount.css";
import RearrangeAccount1 from "../../../Assets/Learn/RearrangeAccount1.webp";
import RearrangeAccount2 from "../../../Assets/Learn/RearrangeAccount2.webp";
import RearrangeAccount3 from "../../../Assets/Learn/RearrangeAccount3.webp";
import RearrangeAccount4 from "../../../Assets/Learn/RearrangeAccount4.webp";
const RearrangeAccount = (props) => {
  return (
    <div className="rearrange-account-main-container">
      <div className="rearrange-account-breadcrumb">
        <p>Accounts</p>
        <p> &gt; </p>
        <p>Re-arrange Account</p>
      </div>
      <div className="rearrange-account-title-div">
        <p>How to re-arrange an account in Best Budget</p>
      </div>
      <div className="rearrange-account-section-container1">
        <div className="rearrange-account-section-container1-description">
          <ol>
            <li>
              In the <span>'Accounts'</span> tab, locate and tap on the{" "}
              <span>'rearrange'</span> icon (represented by 4 horizontal
              parallel lines) positioned at the top right corner of the screen.
            </li>
            <div className="rearrange-account-section-container1-img1-div">
              <img src={RearrangeAccount1} alt="" />
            </div>
            <li>
              Once you are in rearrangement screen, hold and drag the account
              field of the desired account to move it to your preferred position
              within the list.
            </li>
            <li>
              You can position the account field anywhere within the same
              accounts section, either towards the top or bottom only, to
              organize your accounts according to your preferences.
              <br />
              <strong>Note:</strong> You cannot move an account from one account
              type section to another. Each account must remain within its
              designated account type section when rearranging.
            </li>
            <div className="rearrange-account-section-container1-img1-div">
              <img src={RearrangeAccount2} alt="" />
            </div>
            <li>
              After rearranging your accounts as desired, tap on the <span>'Done'</span> icon
              (represented by tick mark) to save the new arrangement.
            </li>
            <div className="rearrange-account-section-container1-img1-div">
              <img src={RearrangeAccount3} alt="" />
            </div>
            <div className="rearrange-account-section-container1-img1-div">
              <img src={RearrangeAccount4} alt="" />
            </div>
          </ol>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default RearrangeAccount;
