import React from "react";
import "./DeleteCateGroup.css";
import AddCatGroup1 from "../../../Assets/Learn/AddCatGroup1.webp";
import DeleteCatGroup2 from "../../../Assets/Learn/DeleteCatGroup2.webp";
import DeleteCatGroup3 from "../../../Assets/Learn/DeleteCatGroup3.webp";
import DeleteCatGroup4 from "../../../Assets/Learn/DeleteCatGroup4.webp";
import DeleteCatGroup5 from "../../../Assets/Learn/DeleteCatGroup5.webp";

const DeleteCateGroup = (props) => {
  return (
    <div className="delete-cate-group-main-container">
      <div className="delete-cate-group-breadcrumb">
        <p>Budget</p>
        <p> &gt; </p>
        <p>Delete Category Group</p>
      </div>
      <div className="delete-cate-group-title-div">
        <p>How to delete a category group in Best Budget</p>
      </div>
      <div className="delete-cate-group-section-container1">
        <div className="delete-cate-group-section-container1-description">
          <ol>
            <li>
              Tap on the <span>'Manage Categories'</span> icon (represented by a pencil
              shape) located in the top right corner of the screen. This will
              direct you to the category management section.
            </li>
            <div className="delete-cate-group-section-container1-img1-div">
              <img src={AddCatGroup1} alt="" />
            </div>
            <li>
              Locate the desired category group you wish to delete.
              <br /> <strong>Note :</strong> A category group can only be
              deleted only if it has no allocations or past transactions for any
              of the categories associated with it.
            </li>
            <li>
              Look for the <span>'menu'</span> icon (represented by minus symbol) positioned
              near the left side of the category group name.{" "}
            </li>
            <li>
              From the menu that appears, select the <span>'Delete'</span> option. This
              action will delete the selected category group from your list.
            </li>
            <div className="delete-cate-group-section-container1-img1-div">
              <img src={DeleteCatGroup2} alt="" />
            </div>
            <div className="delete-cate-group-section-container1-img1-div">
              <img src={DeleteCatGroup3} alt="" />
            </div>
          </ol>
        </div>
      </div>
      <div className="delete-cate-group-title-div">
        <p>How to delete a category group with allocations in Best Budget</p>
      </div>
      <div className="delete-cate-group-section-container1">
        <div className="delete-cate-group-section-container1-description">
          <ol>
            <p>
              <strong>Note:</strong> When there are active categories with
              allocated amounts in a category group, it cannot be deleted
              directly. You must transfer the allocations to another category
              group before deletion.
            </p>
            <div className="delete-cate-group-section-container1-img1-div">
              <img src={DeleteCatGroup4} alt="" />
            </div>
            <div className="delete-cate-group-section-container1-img1-div">
              <img src={DeleteCatGroup5} alt="" />
            </div>
          </ol>
        </div>
      </div>
      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default DeleteCateGroup;
