import React from "react";
import "./Upcoming.css";
import Summary1 from "../../../Assets/Learn/Summary1.webp";
import Summary2 from "../../../Assets/Learn/Summary2.webp";
import Upcoming2 from "../../../Assets/Learn/Upcoming2.webp";
import Upcoming3 from "../../../Assets/Learn/Upcoming3.webp";
import Upcoming4 from "../../../Assets/Learn/Upcoming4.webp";

const Upcoming = (props) => {
  return (
    <div className="upcoming-main-container">
      <div className="upcoming-breadcrumb">
        <p>Dashboard</p>
        <p> &gt; </p>
        <p>Upcoming</p>
      </div>
      <div className="upcoming-title-div">
        <p>Accessing 'Upcoming' transactions in dashboard</p>
      </div>
      <div className="upcoming-section-container1">
        <div className="upcoming-section-container1-description">
          <ol>
            <li>
              Tap on the <span>'Dashboard'</span> icon located in the bottom navigation bar and open Dashboard.
            </li>
            <div className="upcoming-section-container1-img1-div">
              <img src={Summary1} alt="" />
            </div>
            <li>
              By default, the <span>Summary</span> view is displayed when you enter the
              Dashboard.
            </li>
            <li>
              To view the <span>'Upcoming'</span> transactions, tap on the right arrow at the
              bottom of the screen twice to switch to the Upcoming transactions
              view.
            </li>
            <div className="upcoming-section-container1-img3-div">
              <img src={Summary2} alt="" />
            </div>
            <div className="upcoming-section-container1-img3-div">
              <img src={Upcoming2} alt="" />
            </div>
            <li>
              The <span>'Upcoming'</span> section of the Best Budget dashboard provides a
              quick view of all your scheduled transactions at one place,
              allowing you to stay on top of your bills and payments. This helps
              you avoid late fees and ensure that your finances are in order.
            </li>
            <li>
              Tap on the desired scheduled transaction to open and edit its
              details or to delete the transaction.
            </li>
            <div className="upcoming-section-container1-img3-div">
              <img src={Upcoming3} alt="" />
            </div>
            <div className="upcoming-section-container1-img3-div">
              <img src={Upcoming4} alt="" />
            </div>
          </ol>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default Upcoming;
