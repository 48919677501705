import React from "react";
import "./TransferAllocation.css";
import TransferAllocation1 from "../../../Assets/Learn/TransferAllocation1.webp";
import TransferAllocation2 from "../../../Assets/Learn/TransferAllocation2.webp";
import TransferAllocation3 from "../../../Assets/Learn/TransferAllocation3.webp";
import TransferAllocation4 from "../../../Assets/Learn/TransferAllocation4.webp";
import TransferAllocation5 from "../../../Assets/Learn/TransferAllocation5.webp";
import TransferAllocation6 from "../../../Assets/Learn/TransferAllocation6.webp";
import TransferAllocation7 from "../../../Assets/Learn/TransferAllocation7.webp";
const TransferAllocation = (props) => {

  return (
    <div className="transfer-allocation-main-container">
      <div className="transfer-allocation-breadcrumb">
        <p>Budget</p>
        <p> &gt; </p>
        <p>Transfer Allocation</p>
      </div>
      <div className="transfer-allocation-title-div">
        <p>Transfer amount from one category to another</p>
      </div>
      <div className="transfer-allocation-section-container1">
        <div className="transfer-allocation-section-container1-description">
          <ol>
            <li>Locate and tap on the category field associated with the amount you wish to transfer.</li>
            <div className="transfer-allocation-section-container1-img1-div">
              <img src={TransferAllocation1} alt="" />
            </div>
            <li>Tap on the <span>'transfer'</span> option.</li>
            <div className="transfer-allocation-section-container1-img1-div">
              <img src={TransferAllocation2} alt="" />
            </div>
            <li>Enter the amount you intend to transfer from this category.</li>
            <li>Tap on the category field to choose the category where you want to transfer the amount.</li>
            <div className="transfer-allocation-section-container1-img1-div">
              <img src={TransferAllocation3} alt="" />
            </div>
            <li>From the available categories, select the desired category or create a new category to transfer the amount to.</li>
            <div className="transfer-allocation-section-container1-img1-div">
              <img src={TransferAllocation4} alt="" />
            </div>
            <li>Once you've selected the destination category, tap on the <span>'Transfer'</span> button.</li>
            <div className="transfer-allocation-section-container1-img1-div">
              <img src={TransferAllocation5} alt="" />
            </div>
            <li>Select the <span>'Transfer'</span> option from the pop-up window to complete the transaction.</li>
            <div className="transfer-allocation-section-container1-img1-div">
              <img src={TransferAllocation6} alt="" />
            </div>
            <div className="transfer-allocation-section-container1-img1-div">
              <img src={TransferAllocation7} alt="" />
            </div>
          </ol>
        </div>

      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default TransferAllocation;
