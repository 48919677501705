import React from "react";
import backButton from "../../../Assets/Learn/Videos/backButton.svg";
import { useNavigate } from "react-router-dom";
import Footer from "../../Footer";
const OrgainiseYourFinancesByAddingAccounts = () => {
    const navigate = useNavigate();
    const handleNavigation = () => {
        navigate(`/learn?tab=${"Videos"}`);
    };
    return (
        <React.Fragment>
            <div className="learn-main-container">
                <div className="learn-section-container">
                    <div className="learn-section-container1-title">
                        <p>Learn Videos</p>
                    </div>
                    <div
                        className="learn-section-container1-back-navigation-div"
                        onClick={() => {
                            handleNavigation();
                        }}
                    >
                        <img src={backButton} alt="back" />
                        <p>Back to Videos List</p>
                    </div>
                    <div className="learn-section-container1-video">
                        <iframe
                            src="https://www.youtube.com/embed/oLAtw15gs7I?si=fxT_yK7EowPt3ciX"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullScreen="allowFullScreen"
                        ></iframe>
                    </div>
                    <div className="learn-section-container1-description">
                        <h3>Organize Your Finances | Adding & Managing Accounts</h3>
                        <p>
                            Welcome to Best Budget!
                            <br />
                            Accounts in Best Budget are the foundation of your financial
                            tracking. They represent the different sources where your money is
                            stored or flows through, such as bank accounts, credit cards, cash
                            wallets, or investment portfolios.
                        </p>
                        <p>
                            Adding accounts is simple! Just navigate to the Accounts section,
                            and you can start creating and customizing accounts to reflect
                            your financial reality. Whether it’s tracking your savings,
                            monitoring credit card expenses, or keeping an eye on cash
                            transactions, organizing your accounts ensures you have a complete
                            and accurate view of your finances.
                        </p>
                        <p>
                            Keep your accounts updated, and you’ll always have a clear
                            understanding of your financial position, empowering you to make
                            better decisions and reach your goals faster.
                        </p>
                        <p>
                            Download the app now from{" "}
                            <a href="https://play.google.com/store/apps/details?id=com.crexin.bestbudget&hl=en"
                                target="_blank" rel="noreferrer">Play Store</a> and take
                            the first step toward smarter budgeting.
                            <br /> Need help? Email us at{" "}
                            <a href="mailto:support@bestbudget.in">
                                support@bestbudget.in
                            </a>{" "}
                            for assistance.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
};

export default OrgainiseYourFinancesByAddingAccounts;
