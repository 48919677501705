import React from "react";
import "./HideCateGroup.css";
import AddCatGroup1 from "../../../Assets/Learn/AddCatGroup1.webp";
import HideCatGroup2 from "../../../Assets/Learn/HideCatGroup2.webp";
import HideCatGroup3 from "../../../Assets/Learn/HideCatGroup3.webp";
import HideCatGroup4 from "../../../Assets/Learn/HideCatGroup4.webp";
import HideCatGroup5 from "../../../Assets/Learn/HideCatGroup5.webp";
import HideCatGroup6 from "../../../Assets/Learn/HideCatGroup6.webp";
import HideCatGroup7 from "../../../Assets/Learn/HideCatGroup7.webp";
import HideCatGroup8 from "../../../Assets/Learn/HideCatGroup8.webp";

const HideCateGroup = (props) => {
  return (
    <div className="hide-cate-group-main-container">
      <div className="hide-cate-group-breadcrumb">
        <p>Budget</p>
        <p> &gt; </p>
        <p>Hide Category Group</p>
      </div>
      <div className="hide-cate-group-title-div">
        <p>How to Hide a Category group in Best Budget</p>
      </div>
      <div className="hide-cate-group-section-container1">
        <div className="hide-cate-group-section-container1-description">
          <ol>
            <li>
              Tap on the <span>'Manage Categories'</span> icon (represented by a pencil
              shape) located in the top right corner of the screen. This will
              direct you to the category management section.
            </li>
            <div className="hide-cate-group-section-container1-img1-div">
              <img src={AddCatGroup1} alt="" />
            </div>
            <li>Locate the desired category group you wish to <span>'Hide'</span></li>
            <li>
              Look for the <span>'menu'</span> icon (represented by minus symbol) positioned
              near the left side of the category group name.
            </li>
            <li>
              From the menu that appears, select the <span>'Hide'</span> option. This action
              will remove the selected category group from your visible list,
              effectively hiding it from your budget management view.
            </li>
            <div className="hide-cate-group-section-container1-img1-div">
              <img src={HideCatGroup2} alt="" />
            </div>
            <div className="hide-cate-group-section-container1-img1-div">
              <img src={HideCatGroup3} alt="" />
            </div>
          </ol>
        </div>
      </div>
      <div className="hide-cate-group-title-div">
        <p>How to Show a hidden Category group in Best Budget</p>
      </div>
      <div className="hide-cate-group-section-container1">
        <div className="hide-cate-group-section-container1-description">
          <ol>
            <li>
              Tap on the <span>'Manage Categories'</span> icon (represented by a pencil
              shape) located in the top right corner of the screen. This will
              direct you to the category management section.
            </li>
            <div className="hide-cate-group-section-container1-img1-div">
              <img src={HideCatGroup4} alt="" />
            </div>
            <li>
              Look for the <span>'Hidden Categories'</span> button, which is usually located
              at the bottom of the screen. Tap on this button to view all the
              category groups that have been hidden.
            </li>
            <div className="hide-cate-group-section-container1-img1-div">
              <img src={HideCatGroup5} alt="" />
            </div>
            <li>Find the desired category group you wish to show(unhide).</li>
            <li>
              Tap on the <span>'show'</span> icon associated with the desired category group
              to unhide it along with its categories.
            </li>
            <li>
              Alternatively, you can tap on the <span>'Show All'</span> button located at the
              top right of the screen, to unhide all hidden category groups and
              categories at once.
            </li>
            <div className="hide-cate-group-section-container1-img1-div">
              <img src={HideCatGroup6} alt="" />
            </div>
            <li>
              A pop-up window will appear; tap on the <span>'Show'/'Show All'</span> button to unhide.
            </li>
            <div className="hide-cate-group-section-container1-img1-div">
              <img src={HideCatGroup7} alt="" />
            </div>
            <div className="hide-cate-group-section-container1-img1-div">
              <img src={HideCatGroup8} alt="" />
            </div>
          </ol>
        </div>
      </div>
      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default HideCateGroup;
