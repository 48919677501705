import React from "react";
import "./ChangeMpin.css";
import EditProfileDet1 from "../../../Assets/Learn/EditProfileDet1.webp";
import TurnOnMPIN2 from "../../../Assets/Learn/TurnOnMPIN2.webp";
import ChangeMPIN3 from "../../../Assets/Learn/ChangeMPIN3.webp";
import ChangeMPIN4 from "../../../Assets/Learn/ChangeMPIN4.webp";
import ChangeMPIN5 from "../../../Assets/Learn/ChangeMPIN5.webp";

const ChangeMpin = (props) => {
    return (
        <div className="turn-on-mpin-main-container">
            <div className="turn-on-mpin-breadcrumb">
                <p>Profile</p>
                <p> &gt; </p>
                <p>change MPIN</p>
            </div>
            <div className="turn-on-mpin-title-div">
                <p>How to change MPIN in Best Budget</p>
            </div>
            <div className="turn-on-mpin-section-container1">
                <div className="turn-on-mpin-section-container1-description">
                    <ol>
                        <li>
                            Tap on the menu option (represented by a profile icon) located at
                            the top right corner of the Dashboard. Next, from the dropdown,
                            tap on the <span>'Settings'</span> option.
                        </li>
                        <div className="turn-on-mpin-section-container1-img4-div">
                            <img src={EditProfileDet1} alt="" />
                        </div>
                        <li>Tap on the <span>'Configure MPIN'</span> option.</li>
                        <div className="turn-on-mpin-section-container1-img4-div">
                            <img src={TurnOnMPIN2} alt="" />
                        </div>
                        <li>
                            If you wish to change your MPIN, select the <span>'Change MPIN'</span> option.
                        </li>
                        <div className="turn-on-mpin-section-container1-img4-div">
                            <img src={ChangeMPIN3} alt="" />
                        </div>
                        <li>
                            You will be prompted to enter a new 4-digit MPIN of your choice. Re-enter the same MPIN for confirmation.
                        </li>
                        <div className="turn-on-mpin-section-container1-img2-div">
                            <img src={ChangeMPIN4} alt="" />
                        </div>
                        <li>
                            Once the new MPIN is confirmed, click on the <span>'Save'</span> button to store the updated MPIN. This will replace your old MPIN and apply it to future logins.
                        </li>
                        <div className="turn-on-mpin-section-container1-img4-div">
                            <img src={ChangeMPIN5} alt="" />
                        </div>

                    </ol>
                </div>
            </div>

            <div className="learn-go-to-top">
                <div
                    className="go-to-page-top"
                    onClick={() => {
                        props.scrollToTop();
                    }}
                ></div>
            </div>
        </div>
    );
};

export default ChangeMpin;
