import React from "react";
import "./AllocBasedOnPrevStats.css";
import PastStatisticsAllocation1 from "../../../Assets/Learn/PastStatisticsAllocation1.webp";
import PastStatisticsAllocation2 from "../../../Assets/Learn/PastStatisticsAllocation2.webp";
import PastStatisticsAllocation3 from "../../../Assets/Learn/PastStatisticsAllocation3.webp";
import PastStatisticsAllocation4 from "../../../Assets/Learn/PastStatisticsAllocation4.webp";
const AllocBasedOnPrevStats = (props) => {
  return (
    <div className="alloc-based-on-prev-stats-main-container">
      <div className="alloc-based-on-prev-stats-breadcrumb">
        <p>Budget</p>
        <p> &gt; </p>
        <p>Allocate based on past statistics</p>
      </div>
      <div className="alloc-based-on-prev-stats-title-div">
        <p>Allocate based on past statistics in Best Budget</p>
      </div>
      <div className="alloc-based-on-prev-stats-section-container1">
        <div className="alloc-based-on-prev-stats-section-container1-description">
          <ol>
            <li>
              Locate and tap on the category field you wish to allocate amount.
            </li>
            <div className="alloc-based-on-prev-stats-section-container1-img1-div">
              <img src={PastStatisticsAllocation1} alt="" />
            </div>
            <li>
              Choose from the provided options such as <span>'Average Spent'</span>,
              <span> 'Allocated Last Month'</span>, or <span>'Spent Last Month'</span> to allocate the
              amount the selected category based on previous statistics.
            </li>
            <div className="alloc-based-on-prev-stats-section-container1-img1-div">
              <img src={PastStatisticsAllocation2} alt="" />
            </div>
            <li>
              Tap on the <span>'Allocate'</span> option to confirm and execute the
              allocation.
            </li>
            <div className="alloc-based-on-prev-stats-section-container1-img1-div">
              <img src={PastStatisticsAllocation3} alt="" />
            </div>
            <div className="alloc-based-on-prev-stats-section-container1-img1-div">
              <img src={PastStatisticsAllocation4} alt="" />
            </div>
          </ol>
        </div>
      </div>


      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default AllocBasedOnPrevStats;
