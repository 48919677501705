import React from "react";
import "./ChangeEmail.css";
import EditProfileDet1 from "../../../Assets/Learn/EditProfileDet1.webp";
import ChangeEmail2 from "../../../Assets/Learn/ChangeEmail2.webp";
import ChangeEmail3 from "../../../Assets/Learn/ChangeEmail3.webp";
import ChangeEmail4 from "../../../Assets/Learn/ChangeEmail4.webp";
import ChangeEmail5 from "../../../Assets/Learn/ChangeEmail5.webp";
import ChangeEmail6 from "../../../Assets/Learn/ChangeEmail6.webp";
import ChangeEmail7 from "../../../Assets/Learn/ChangeEmail7.webp";
const ChangeEmail = (props) => {
  return (
    <div className="change-email-main-container">
      <div className="change-email-breadcrumb">
        <p>Profile</p>
        <p> &gt; </p>
        <p>Change Email</p>
      </div>
      <div className="change-email-title-div">
        <p>How to change an email in Best Budget</p>
      </div>
      <div className="change-email-section-container1">
        <div className="change-email-section-container1-description">
          <ol>
            <li>
              Tap on the menu option (represented by a profile icon) located at
              the top right corner of the Dashboard. Next, from the dropdown,
              tap on the <span>'Settings'</span> option.
            </li>
            <div className="change-email-section-container1-img4-div">
              <img src={EditProfileDet1} alt="" />
            </div>
            <li>
              Tap on the <span>'Email'</span> field to bring it into edit mode
              for updating the email address.
            </li>
            <div className="change-email-section-container1-img4-div">
              <img src={ChangeEmail2} alt="" />
            </div>
            <li>
              Enter the new email address into the field and tap on{" "}
              <span>'UPDATE'</span> button.
            </li>
            <div className="change-email-section-container1-img4-div">
              <img src={ChangeEmail4} alt="" />
            </div>
            <li>
              Tap on the <span>'Generate'</span> button to receive a one-time
              password (OTP). OTP will be sent to new email address entered in
              the previous step.
            </li>
            <div className="change-email-section-container1-img4-div">
              <img src={ChangeEmail5} alt="" />
            </div>
            <li>
              Enter the OTP received in your email into the provided field.{" "}
            </li>
            <li>
              After entering the OTP, tap on the <span>'Verify'</span> button to
              update the email address.
            </li>
            <div className="change-email-section-container1-img4-div">
              <img src={ChangeEmail6} alt="" />
            </div>
            <div className="change-email-section-container1-img4-div">
              <img src={ChangeEmail7} alt="" />
            </div>
          </ol>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default ChangeEmail;
