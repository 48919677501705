import React from "react";
import "./IncomeVsExpense.css";
import SpendingAnalysis1 from "../../../Assets/Learn/SpendingAnalysis1.webp";
import SpendingAnalysis2 from "../../../Assets/Learn/SpendingAnalysis2.webp";
import Networth3 from "../../../Assets/Learn/Networth3.webp";
import Networth4 from "../../../Assets/Learn/Networth4.webp";
import Networth5 from "../../../Assets/Learn/Networth5.webp";
import Networth6 from "../../../Assets/Learn/Networth6.webp";
const IncomeVsExpense = (props) => {
  return (
    <div className="income-vs-expense-main-container">
      <div className="income-vs-expense-breadcrumb">
        <p>Reports</p>
        <p> &gt; </p>
        <p>Networth</p>
      </div>
      <div className="income-vs-expense-title-div">
        <p>Networth report in Best Budget</p>
      </div>
      <div className="income-vs-expense-section-container1">
        <div className="income-vs-expense-section-container1-description">
          <ol>
            <li>
              Tap on the <span>'More'</span> icon in the navigation bar and select the
              <span> 'Reports'</span> option.
            </li>
            <div className="income-vs-expense-section-container1-img1-div">
              <img src={SpendingAnalysis1} alt="" />
            </div>
            <div className="income-vs-expense-section-container1-img1-div">
              <img src={SpendingAnalysis2} alt="" />
            </div>
            <li>
              By default, the <span>'Spending Analysis'</span> tab opens, offering detailed
              insights into your spending patterns. To view your overall
              financial health, tap the <span>'Net Worth'</span> tab at the bottom to access
              a comprehensive analysis of your assets and liabilities.
            </li>
            <div className="income-vs-expense-section-container1-img3-div">
              <img src={Networth3} alt="" />
            </div>
            <li>
              To refine the report view, tap on the <span>'Filters'</span> icon (represented
              by 3 vertical lines) to open filter options.
            </li>
            <div className="income-vs-expense-section-container1-img4-div">
              <img src={Networth4} alt="" />
            </div>
            <li>
              In the Accounts section, you’ll see all accounts pre-selected
              under <span>'Select All'</span> option.
              <ol type="a">
                <li>
                  Tap <span>'Select None'</span> to uncheck all accounts and then pick
                  specific ones.
                </li>
                <li>
                  Or, tap individual accounts to uncheck only those you want to
                  exclude.
                </li>
              </ol>

            </li>
            <div className="income-vs-expense-section-container1-img3-div">
              <img src={Networth5} alt="" />
            </div>
            <li>Tap on <span>'Apply'</span> button to apply the filters.</li>
            <li>Click on <span>'Reset'</span> button to clear all applied filters at once.</li>
            <div className="income-vs-expense-section-container1-img3-div">
              <img src={Networth6} alt="" />
            </div>
          </ol>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default IncomeVsExpense;
