import React from "react";
import backButton from "../../../Assets/Learn/Videos/backButton.svg";
import { useNavigate } from "react-router-dom";
import Footer from "../../Footer";
const YourFinancialOverviewDashboardReports = () => {
    const navigate = useNavigate();
    const handleNavigation = () => {
        navigate(`/learn?tab=${"Videos"}`);
    };
    return (
        <React.Fragment>
            <div className="learn-main-container">
                <div className="learn-section-container">
                    <div className="learn-section-container1-title">
                        <p>Learn Videos</p>
                    </div>
                    <div className="learn-section-container1-back-navigation-div" onClick={() => { handleNavigation() }}>
                        <img src={backButton} alt="back" />
                        <p>Back to Videos List</p>
                    </div>
                    <div className="learn-section-container1-video">
                        <iframe
                            src="https://www.youtube.com/embed/rnnUaH93Ttw?si=kFsAzfneOBnHuxIr"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullScreen="allowFullScreen"
                        ></iframe>
                    </div>
                    <div className="learn-section-container1-description">
                        <h3>Your Financial Overview | Dashboard & Reports Explained</h3>
                        <p>
                            Welcome to Best Budget! In this video, we’ll take you through the
                            Dashboard of Best Budget, where you’ll get a clear and concise
                            overview of your finances.
                        </p>
                        <p>
                            The Summary section gives key insights into your financial
                            situation, including your net worth, total allocations, and
                            spending. You can also track your biggest expenses in the Top
                            Spenders section and keep an eye on your upcoming transactions to
                            stay ahead of your budget planning. The Allocations section helps
                            you monitor your budgeted amounts, while the Upcoming section
                            ensures you never miss a payment or future expense.
                        </p>
                        <p>
                            Next, we’ll dive into the Reports section, where you can analyze
                            your financial data in more detail. The Spending Analysis feature
                            lets you evaluate your spending habits, so you can easily see where
                            your money is going. The Net Worth report shows a breakdown of your
                            assets and liabilities, helping you track your overall financial
                            health. These two reports give you the tools to stay in control of
                            your finances and make better-informed decisions for your financial
                            future.
                        </p>
                        <p>
                            Join us to discover how the Dashboard and Reports can simplify your
                            financial tracking, giving you everything you need to manage your
                            money effectively and achieve your goals!
                        </p>
                        <p>
                            Download the app now from{" "}
                            <a href="https://play.google.com/store/apps/details?id=com.crexin.bestbudget&hl=en"
                                target="_blank" rel="noreferrer">Play Store</a> and take
                            the first step toward smarter budgeting.
                            <br /> Need help? Email us at{" "}
                            <a href="mailto:support@bestbudget.in">support@bestbudget.in</a> for
                            assistance.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
};

export default YourFinancialOverviewDashboardReports;
