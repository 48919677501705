import React, { useState, useReducer, useEffect } from "react";
import "./AboutUs.css";
import axios from "axios";
import errorIcon from "../Assets/AboutUs/errorIcon.svg";
import Footer from "./Footer";
import Lottie from "lottie-react";
import ThankYou from "../Assets/AboutUs/Thankyou.json";
import section1Image from "../Assets/AboutUs/section1Image.webp";
import TwoWomen from "../Assets/AboutUs/TwoWomen.webp";
import TwoWomenResponsive from "../Assets/AboutUs/TwoWomenResponsive.webp";
import TwoMen from "../Assets/AboutUs/TwoMen.webp";
import handInHand from "../Assets/AboutUs/HandInHand.webp";
import customerCentric from "../Assets/AboutUs/CustomerCentricICon.svg";
import AdaptiveIcon from "../Assets/AboutUs/AdaptiveIcon.svg";
import InnovativeIcon from "../Assets/AboutUs/InnovativeIcon.svg";
import IntegrityIcon from "../Assets/AboutUs/IntegrityIcon.svg";
import confusedWoman from "../Assets/AboutUs/ConfusedWoman.webp";
import confusedMan from "../Assets/AboutUs/ConfusedMan.webp";
import environment from "../environment";
import SEO from "./SEO";
const AboutUs = (props) => {
  const [enableNextScreen, setEnableNextScreen] = useState(false);
  const [geoLocation, setGeoLocation] = useState({ city: "", state: "" });
  const [formErrors, setFormErrors] = useState({
    fullname: "",
    email: "",
    suggestionText: "",
  });
  const [suggestionRegister, updateSuggestionRegister] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    { fullname: "", email: "", suggestionText: "" }
  );
  const getGeoLocation = async () => {
    try {
      const response = await axios.get("https://api.ipgeolocation.io/ipgeo", {
        params: { apiKey: "c599697c9c2c439c82a5a12dbc5835c8" },
      });
      const { city, state_prov: state } = response.data;

      setGeoLocation({ city, state });
    } catch (error) {
      console.error("Error fetching geo-location:", error);
    }
  };
  const handleTextInput = (e) => {
    let text = e.target.value;
    updateSuggestionRegister({
      suggestionText: text,
    });
  };
  const validateForm = () => {
    let errors = {};
    let isValid = true;
    console.log(suggestionRegister.fullname.length);
    let regex = /^[A-Za-z][A-Za-z\'\-]+([\ A-Za-z][A-Za-z\'\-]+)*$/;
    if (!suggestionRegister.fullname) {
      errors.fullname = "Required field";
      isValid = false;
    } else if (
      !regex.test(suggestionRegister.fullname) ||
      suggestionRegister.fullname.length < 3
    ) {
      errors.fullname = "Please enter a valid fullname";
      isValid = false;
    }

    if (!suggestionRegister.email) {
      errors.email = "Required field";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(suggestionRegister.email)) {
      errors.email = "Invalid email address";
      isValid = false;
    }

    if (!suggestionRegister.suggestionText) {
      errors.suggestionText = "Required field";
      isValid = false;
    } else if (suggestionRegister.suggestionText.length < 20) {
      errors.suggestionText = "Minimum 20 characters required";
      isValid = false;
    }
    setFormErrors(errors);
    return isValid;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    let submitSuggestionUrl = environment.baseUrl + "api/suggestions/";
    let postData = {
      suggestion: suggestionRegister.suggestionText
        ? suggestionRegister.suggestionText
        : "",
      fullName: suggestionRegister.fullname ? suggestionRegister.fullname : "",
      email: suggestionRegister.email ? suggestionRegister.email : "",
      city: geoLocation.city ? geoLocation.city : "",
      state: geoLocation.state ? geoLocation.state : "",
    };
    if (validateForm()) {
      let res = await axios.post(submitSuggestionUrl, postData);
      let resData = res.data;
      if (resData.status === 200) {
        updateSuggestionRegister({
          fullname: "",
          email: "",
          suggestionText: "",
        });
        setFormErrors({
          fullname: "",
          email: "",
          suggestionText: "",
        });
        setEnableNextScreen(true);
      }
    } else {
      console.log("Please enter valid details");
    }
  };

  useEffect(() => {
    getGeoLocation(); // Fetch country codes when the component loads
  }, []);
  return (
    <React.Fragment>
      <SEO
        title="About Us"
        description="Our goal is to empower you to attain financial independence; a state where you possess both the resources and knowledge necessary to self-sustain, fostering a profound sense of freedom and security without dependence on others."
      />
      <div className="about-main-container">
        <div className="about-section-background-container1">
          <div className="about-section-content-div">
            <div className="about-section-container1-title-div">
              <p>Achieve Financial Independence</p>
            </div>
            <div className="about-section-container1-description">
              <p>
                Our goal is to empower you to attain financial independence; a
                state where you possess both the resources and knowledge
                necessary to self-sustain, fostering a profound sense of freedom
                and security without dependence on others.
              </p>
            </div>
            <div className="about-section-container1-image-div">
              <div className="about-section-container1-image">
                <img src={section1Image} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="about-section-container2">
          <div className="about-section-container2-background-div"></div>
          <div className="about-section-container2-content-div">
            <div className="about-section-cont2-chandler-image-div">
              <div className="chandler1"></div>
              <div className="chandler2"></div>
              <div className="chandler3"></div>
            </div>
            <div className="about-section-container2-part2">
              <div className="about-section-container2-actual-content">
                <div className="about-section-container2-ac-title-div">
                  <p>Who We are..</p>
                </div>
                <div className="about-section-container2-ac-description">
                  <p>
                    We are a team of believers and doers striving to help people
                    know about their financial position and take informative
                    decisions to attain financial stability and peace of mind.
                  </p>
                </div>
              </div>
              <div className="about-section-container2-second-image-tray">
                <div className="about-section-container2-second-image-tray-img1"></div>
                <div className="about-section-container2-second-image-tray-img2"></div>
                <div className="about-section-container2-second-image-tray-img3"></div>
              </div>
            </div>
            <div className="about-section-container2-part3">
              <div className="about-section-container2-part3-img1-div">
                <div className="about-section-container2-part3-img1">
                  <img
                    src={
                      window.innerWidth > 432 ? TwoWomen : TwoWomenResponsive
                    }
                    alt="Two women discussing features of Best Budget personal finance app, standing in front of a desk with a laptop."
                  />
                </div>
              </div>
              <div className="about-section-container2-part3-img2-div">
                <div className="about-section-container2-part3-img2">
                  <img
                    src={TwoMen}
                    alt="Two men collaborating on a computer in an office, discussing new features for the Best Budget personal finance application."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="about-section-container3">
          <div className="about-section-container3-layer1-div">
            <img
              src={handInHand}
              alt="A heart held by multiple hands, symbolizing unity, love, and support."
            />
          </div>
          <div className="about-section-container3-layer2-div"></div>
          <div className="about-section-container3-layer3-div">
            <div className="about-section-container3-layer3-title">
              <p>Our Values</p>
            </div>
            <div className="about-section-container3-layer3-grid-div">
              <div className="about-section-container3-layer3-grid1">
                <div className="about-section-container3-layer3-grid1-img-div">
                  <div className="about-section-container3-layer3-grid1-img">
                    <img
                      src={customerCentric}
                      alt="Symbolic purple circle on black background showcasing customer-centric approach in premier budget personal finance app."
                    />
                  </div>
                </div>
                <div className="about-section-container3-layer3-grid1-description">
                  <p>Customer Centric</p>
                </div>
              </div>
              <div className="about-section-container3-layer3-grid2">
                <div className="about-section-container3-layer3-grid2-img-div">
                  <div className="about-section-container3-layer3-grid2-img">
                    <img
                      src={AdaptiveIcon}
                      alt="An icon representing the adaptive approach of the team of people who developed the Best Budget personal finance application"
                    />
                  </div>
                </div>
                <div className="about-section-container3-layer3-grid2-description">
                  <p>Adaptive</p>
                </div>
              </div>
              <div className="about-section-container3-layer3-grid3">
                <div className="about-section-container3-layer3-grid3-img-div">
                  <div className="about-section-container3-layer3-grid3-img">
                    <img
                      src={InnovativeIcon}
                      alt="Iconic green light bulb with green light, symbolizing innovative ability of team of people who created Best Budget personal finance  application"
                    />
                  </div>
                </div>
                <div className="about-section-container3-layer3-grid3-description">
                  <p>Innovative</p>
                </div>
              </div>
              <div className="about-section-container3-layer3-grid4">
                <div className="about-section-container3-layer3-grid4-img-div">
                  <div className="about-section-container3-layer3-grid4-img">
                    <img
                      src={IntegrityIcon}
                      alt="Icon of red star and medal representing trustworthiness in Best Budget finance app."
                    />
                  </div>
                </div>
                <div className="about-section-container3-layer3-grid4-description">
                  <p>Integrity</p>
                </div>
              </div>
            </div>
            <div className="about-section-container3-layer3-title2">
              <p>Our Promise</p>
            </div>
            <div className="about-section-container3-layer3-description">
              <p>
                Our promise is to provide you with a simple and intuitive
                budgeting experience. We understand that managing finances can
                be daunting, so we've designed our application to be easy to use
                for everyone, regardless of their financial expertise.
              </p>
              <p>
                We promise full transparency in how we handle your financial
                data. You can trust that your information is secure and that we
                will never sell or share it with third parties without your
                consent.
              </p>
            </div>
          </div>
        </div>
        <div className="about-section-container4">
          <div className="about-section-container4-title-div">
            <p>Tell us what’s in your Mind</p>
          </div>
          <div
            className="about-section-container4-content-div"
            id="scrollToBottom"
          >
            <div
              className={
                enableNextScreen
                  ? "diabled-image-tray"
                  : "about-section-container4-image-tray"
              }
            >
              <div className="about-section-container-image1">
                <img
                  src={confusedWoman}
                  alt="A cartoon woman with a question mark on her head, symbolizing confusion while using the Best Budget personal finance application"
                />
              </div>
              <div className="about-section-container-image2">
                <img
                  src={confusedMan}
                  alt="A bearded man with a question mark on his head, symbolizing confusion while seeking assistance from the support team of a budget finance app."
                />
              </div>
            </div>
            {enableNextScreen ? (
              <div className="about-section-container4-form-div">
                <p>We will get back to you soon</p>
                <div className="thank-you-animation">
                  <Lottie animationData={ThankYou} loop={true} />
                </div>
                <div className="btn-div">
                  <button
                    className="close-button"
                    onClick={() => {
                      setEnableNextScreen(false);
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            ) : (
              <div className="about-section-container4-form-div">
                <p>We would love to hear it from you</p>
                <div className="form-div">
                  <form
                    className="needs-validation contact-us-form"
                    noValidate
                    onSubmit={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    <div className="input-group fullname-group has-validation">
                      <input
                        type="text"
                        placeholder="What should we call you ? *"
                        className={`form-control formdata formdata-fullname ${formErrors.fullname && "is-invalid"
                          }`}
                        id="validationCustomUsername"
                        required={true}
                        value={
                          suggestionRegister.fullname
                            ? suggestionRegister.fullname
                            : ""
                        }
                        onFocus={() => {
                          setFormErrors({ ...formErrors, fullname: "" });
                        }}
                        onChange={(e) => {
                          updateSuggestionRegister({
                            fullname: e.target.value,
                          });
                        }}
                      />
                      <div
                        className={
                          formErrors.fullname
                            ? "invalid-feedback fullname-feedback"
                            : "valid-feedback"
                        }
                      >
                        <img alt="" src={errorIcon} className="error-icon" />
                        <p>{formErrors.fullname}</p>
                      </div>
                    </div>
                    <div className="input-group email-group has-validation">
                      <input
                        type="text"
                        placeholder="Your Email *"
                        className={`form-control formdata formdata-email ${formErrors.email && "is-invalid"
                          }`}
                        // id="validationCustomUsername"
                        required={true}
                        value={
                          suggestionRegister.email
                            ? suggestionRegister.email
                            : ""
                        }
                        onFocus={() => {
                          setFormErrors({ ...formErrors, email: "" });
                        }}
                        onChange={(e) => {
                          updateSuggestionRegister({
                            email: e.target.value,
                          });
                        }}
                      />
                      <div
                        className={
                          formErrors.email
                            ? "invalid-feedback email-feedback"
                            : "valid-feedback"
                        }
                      >
                        <img alt="" className="error-icon" src={errorIcon} />

                        <p>{formErrors.email}</p>
                      </div>
                    </div>
                    <div className="form-group suggestions-form-textarea">
                      <textarea
                        className={`form-control formdata formdata-textarea ${formErrors.suggestionText && "is-invalid"
                          }`}
                        type="textarea"
                        placeholder="Dear Best Budget.."
                        value={
                          suggestionRegister.suggestionText
                            ? suggestionRegister.suggestionText
                            : ""
                        }
                        onFocus={() => {
                          setFormErrors({ ...formErrors, suggestionText: "" });
                        }}
                        onChange={(e) => {
                          handleTextInput(e);
                        }}
                        required={true}
                      />
                      <div
                        className={
                          formErrors.suggestionText
                            ? "invalid-feedback suggestion-feedback"
                            : "valid-feedback"
                        }
                      >
                        <img alt="" className="error-icon" src={errorIcon} />
                        <p>{formErrors.suggestionText}</p>
                      </div>
                    </div>
                    <div className="submit-button-div">
                      <button type="submit" className="submit-button">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
                <div className="footer-text">
                  <p>
                    Your email is safe, and you won’t receive any unwanted
                    emails.
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="about-section-container4-footer">
            <p>
              You can also write to{" "}
              <span>
                {" "}
                <a href="mailto:support@bestbudget.in">support@bestbudget.in</a> {" "}
              </span>
              for any suggestions, queries or concerns. We will get back to you
              within 24 Hrs.
            </p>
          </div>
          <div className="aboutus--go-to-top">
            <div
              className="go-to-page-top"
              onClick={() => {
                props.scrollToTop();
              }}
            ></div>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default AboutUs;
