import React from "react";
import GetSupport1 from "../../../Assets/Learn/GetSupport1.webp";
import GetSupport2 from "../../../Assets/Learn/GetSupport2.webp";
import GetSupport3 from "../../../Assets/Learn/GetSupport3.webp";

const GetSupport = (props) => {
    return (
        <div className="turn-off-mpin-main-container">
            <div className="turn-off-mpin-breadcrumb">
                <p>Profile</p>
                <p> &gt; </p>
                <p>Get Support</p>
            </div>
            <div className="turn-off-mpin-title-div">
                <p>How to Get Support in Best Budget</p>
            </div>
            <div className="turn-off-mpin-section-container1">
                <div className="turn-off-mpin-section-container1-description">
                    <ol>
                        <li>
                            Tap on the menu option (represented by a profile icon) located at
                            the top right corner of the Dashboard. Next, from the dropdown,
                            tap on the <span>'Support'</span> option.
                        </li>
                        <div className="turn-off-mpin-section-container1-img4-div">
                            <img src={GetSupport1} alt="" />
                        </div>

                        <li>
                            You will be directed to the Support page. For any queries or
                            assistance, you can email us at{" "}
                            <a href="mailto:support@bestbudget.in">support@bestbudget.in</a>.
                        </li>
                        <li>
                            Click on the support email to open your default mailing platform,
                            such as Gmail, Outlook, or others, directly.
                        </li>
                        <div className="turn-off-mpin-section-container1-img4-div">
                            <img src={GetSupport2} alt="" />
                        </div>
                        <li>Select the <span>Close</span> button to return to the dashboard screen.</li>
                        <div className="turn-off-mpin-section-container1-img4-div">
                            <img src={GetSupport3} alt="" />
                        </div>
                    </ol>
                </div>
            </div>

            <div className="learn-go-to-top">
                <div
                    className="go-to-page-top"
                    onClick={() => {
                        props.scrollToTop();
                    }}
                ></div>
            </div>
        </div>
    );
};

export default GetSupport;
