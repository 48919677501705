import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import Header from "./Components/Header";
import LandingWebsite from "./Components/LandingWebsite";
import BestBudgetWay from "./Components/BestBudgetWay";
import PrivacyPolicy from "./Components/Legal/PrivacyPolicy";
import Pricing from "./Components/Pricing";
import ServiceTerms from "./Components/Legal/ServiceTerms";
import Security from "./Components/Legal/Security";
import CookiesPolicy from "./Components/Legal/CookiesPolicy";
import Learn from "./Components/Learn";
import LearningCenter from "./Components/Learn/LearningCenter";
import AboutUs from "./Components/AboutUs";
import { MenuProvider } from "./MenuContext";
import Blog from "./Components/Blog";
import FinancialPlanning from "./Components/Blogs/FinancialPlanning";
import BudgetingBlog from "./Components/Blogs/BudgetingBlog";
import InvestingBlog from "./Components/Blogs/InvestingBlog";
import SavingBlog from "./Components/Blogs/SavingBlog";
// import SubscriptionPage from "./Components/SubscriptionPage";
import { HelmetProvider } from "react-helmet-async";
import QuickAccessSignUpLogin from "./Components/Learn/Videos/QuickAccessSignUpLogin";
import FirstStepsSettingUpBestBudget from "./Components/Learn/Videos/FirstStepsSettingUpBestBudget";
import OrgainiseYourFinancesByAddingAccounts from "./Components/Learn/Videos/OrgainiseYourFinancesByAddingAccounts";
import ManagingBudgetCategories from "./Components/Learn/Videos/ManagingBudgetCategories";
import TrackManagePayeesAndTransactions from "./Components/Learn/Videos/TrackManagePayeesAndTransactions";
import YourFinancialOverviewDashboardReports from "./Components/Learn/Videos/YourFinancialOverviewDashboardReports";
import ManageBudgetsAndPersonalizeSettings from "./Components/Learn/Videos/ManageBudgetsAndPersonalizeSettings";

const App = () => {
  const helmetContext = {};
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use smooth scrolling for a nicer effect
    });
  };
  const openExternalLink = (url) => {
    window.open(url, "_blank"); // Opens in a new tab
  };
  function closeBanner() {
    document.getElementById("banner").style.display = "none";
  }
  return (
    <MenuProvider>
      <div className="App">
        <div class="fixed-banner" id="banner">
          <span>
            Best Budget is coming soon. Your Feedback Powers Innovation: Join
            Our Beta Test and Make a Difference.{" "}
            <a
              href="https://play.google.com/store/apps/details?id=com.crexin.bestbudget&hl=en"
              target="_blank"

            >
              Download
            </a>
          </span>
          <button
            class="close-btn"
            onClick={() => {
              closeBanner();
            }}
          >
            ✖
          </button>
        </div>
        {/* <div className="empty-head"></div> */}
        <BrowserRouter>

          <Header />
          <div className="browser-router-container">
            <HelmetProvider context={helmetContext}>
              <Routes>
                <Route
                  path="/"
                  element={
                    <LandingWebsite
                      scrollToTop={scrollToTop}
                      openExternalLink={openExternalLink}
                    />
                  }
                />
                <Route
                  path="/why-best-budget"
                  element={
                    <BestBudgetWay
                      scrollToTop={scrollToTop}
                      openExternalLink={openExternalLink}
                    />
                  }
                />
                <Route
                  path="/pricing"
                  element={
                    <Pricing
                      scrollToTop={scrollToTop}
                      openExternalLink={openExternalLink}
                    />
                  }
                />
                <Route path="/learn" element={<Learn scrollToTop={scrollToTop} />} />
                <Route
                  path="/about-us"
                  element={<AboutUs scrollToTop={scrollToTop} />}
                />
                <Route path="/blog" element={<Blog />} />
                <Route
                  path="/privacy-policy"
                  element={<PrivacyPolicy scrollToTop={scrollToTop} />}
                />
                <Route
                  path="/service-terms"
                  element={<ServiceTerms scrollToTop={scrollToTop} />}
                />
                <Route
                  path="/security"
                  element={<Security scrollToTop={scrollToTop} />}
                />
                <Route
                  path="/cookies-policy"
                  element={<CookiesPolicy scrollToTop={scrollToTop} />}
                />
                <Route
                  path="/learn/learning-center"
                  element={<LearningCenter scrollToTop={scrollToTop} />}
                />

                {/* <Route
                  path="/subscription-page"
                  element={<SubscriptionPage />}
                /> */}
                <Route
                  path="/blog/need-to-achieve-good-outcome-from-financial-planning"
                  element={<FinancialPlanning openExternalLink={openExternalLink} />}
                />
                <Route
                  path="/blog/how-budgeting-drives-financial-success-and-management"
                  element={<BudgetingBlog openExternalLink={openExternalLink} />}
                />
                <Route
                  path="/blog/top-tips-for-tracking-all-your-investments-in-one-place"
                  element={<InvestingBlog openExternalLink={openExternalLink} />}
                />
                <Route
                  path="/blog/effective-tips-for-saving-money-quickly-and-easily"
                  element={<SavingBlog openExternalLink={openExternalLink} />}
                />
                <Route
                  path="/learn/videos/quick-access-sign-up-login"
                  element={<QuickAccessSignUpLogin />}
                />
                <Route
                  path="/learn/videos/your-first-steps-setting-up-best-budget-like-a-pro"
                  element={<FirstStepsSettingUpBestBudget />}
                />
                <Route
                  path="/learn/videos/organize-your-finances-adding-managing-accounts"
                  element={<OrgainiseYourFinancesByAddingAccounts />}
                />
                <Route
                  path="/learn/videos/managing-your-budget-categories-setting-budget-allocations"
                  element={<ManagingBudgetCategories />}
                />
                <Route
                  path="/learn/videos/track-manage-payments-payees-transactions-made-simple"
                  element={<TrackManagePayeesAndTransactions />}
                />
                <Route
                  path="/learn/videos/your-financial-overview-dashboard-reports-explained"
                  element={<YourFinancialOverviewDashboardReports />}
                />
                <Route
                  path="/learn/videos/manage-budgets-and-personalize-settings"
                  element={<ManageBudgetsAndPersonalizeSettings />}
                />
              </Routes>
            </HelmetProvider>
          </div>
        </BrowserRouter>
        <CookieConsent
          className="cookies-consent-container"
          location="bottom"
          buttonText="Accept All"
          declineButtonText="Decline"
          enableDeclineButton
          cookieName="CREXIN Technologies"
          style={{
            background: "#1080e3",
            height: window.innerWidth > 440 ? "auto" : "120px",
            width: window.innerWidth > 440 ? "1080px" : "100%",
            left: window.innerWidth > 440 ? "auto" : "0px",
            textAlign: window.innerWidth > 440 ? "left" : "center",
            fontSize: window.innerWidth > 440 ? "16px" : "12px",
            margin: window.innerWidth > 440 ? "0px 50px" : "0px auto",
            // display: "flex", // Use flexbox
            // flexDirection: window.innerWidth > 430 ? "row" : "column", // Stack buttons vertically on mobile
            justifyContent: "center", // Center buttons horizontally
            alignItems: "center",
          }}
          buttonStyle={{
            background: "#47AF3F",
            color: "#FFFFFF",
            fontSize: window.innerWidth > 440 ? "16px" : "12px",
            borderRadius: "5px",
            margin: window.innerWidth > 440 ? "20px 20px" : "10px 10px",
          }}
          declineButtonStyle={{
            fontSize: window.innerWidth > 440 ? "16px" : "12px",
            borderRadius: "5px",
            margin: window.innerWidth > 440 ? "20px 20px" : "10px 10px",
          }}
          expires={150}
          onAccept={() => {
            console.log("Cookies accepted");
          }}
          onDecline={() => {
            console.log("Cookies declined");
          }}
        >
          We use cookies to improve your experience. By continuing to use our
          website, you agree to our use of cookies.{" "}
          <a href="/cookies-policy" style={{ color: "#FFD700" }}>
            Learn More
          </a>
        </CookieConsent>
      </div>
    </MenuProvider>
  );
};

export default App;
