import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./Learn.css";
import CategoriesIcon from "../Assets/Learn/CategoriesIcon.svg";
import AccountsIcon from "../Assets/Learn/AccountsIcon.svg";
import TransactionsIcon from "../Assets/Learn/TransactionsIcon.svg";
import DashboardIcon from "../Assets/Learn/DashboardIcon.svg";
import ReportsIcon from "../Assets/Learn/ReportsIcon.svg";
import PayeesIcon from "../Assets/Learn/PayeesIcon.svg";
import ProfileIcon from "../Assets/Learn/ProfileIcon.svg";
import OthersIcon from "../Assets/Learn/OthersIcon.svg";
import boyRunning from "../Assets/Learn/boyRunning.webp";
import Footer from "./Footer";
import SEO from "./SEO";
import SignupThumbnail from "../Assets/Learn/Videos/SignupThumbnail.webp";
import SettingUpBestBudgetThumbnail from "../Assets/Learn/Videos/SettingUpBestBudgetThumbnail.webp";
import AccountsThumbnail from "../Assets/Learn/Videos/AccountsThumbnail.webp";
import BudgetThumbnail from "../Assets/Learn/Videos/BudgetThumbnail.webp";
import PayeesThumbnail from "../Assets/Learn/Videos/PayeesThumbnail.webp";
import DashboardThumbnail from "../Assets/Learn/Videos/DashboardThumbnail.webp";
import ProfileSettingsThumbnail from "../Assets/Learn/Videos/ProfileSettingsThumbnail.webp";
const Learn = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialTab = queryParams.get('tab') || 'Guides';
  const [active, setActive] = useState(initialTab);

  const handleToggle = (option) => {
    setActive(option);
    navigate(`/learn?tab=${option}`);
  };
  const handleNavigation = ({ module, path }) => {
    navigate("/learn/learning-center", {
      state: { module: module, path: path },
    });
  };
  const videosGridData = [
    {
      id: 1,
      videoTitle: "Quick Access: Sign Up & Login 🔐 | Best Budget App",
      videoPath: "/learn/videos/quick-access-sign-up-login",
      videoUrl: "https://www.youtube.com/embed/VoJ_pcJyHeM?si=RVN9iHk2yvBQeBcN",
      videoDescription:
        "In this video, We'll walk you through setting up your account, signing up, and logging in with ease. Whether you’re new to Best Budget or returning to take charge of your finances.",
      videoThumbnail: SignupThumbnail,
      videoBorderColor: "#CEE3E8",
    },
    {
      id: 2,
      videoTitle: "Your First Steps 🚀 | Setting Up Best Budget Like a Pro",
      videoUrl: "https://www.youtube.com/embed/ZM_GO84HYCE?si=TtVUU-BBe64WxpV1",
      videoPath: "/learn/videos/your-first-steps-setting-up-best-budget-like-a-pro",
      videoDescription:
        "In this video, we'll guide you step-by-step through setting up your account and share essential tips to help you manage your finances effectively for the long term.",
      videoThumbnail: SettingUpBestBudgetThumbnail,
      videoBorderColor: "#E9E9E9",
    },
    {
      id: 3,
      videoTitle: "Organize Your Finances 🏦 | Adding & Managing Accounts",
      videoUrl: "https://www.youtube.com/embed/oLAtw15gs7I?si=KCc7NeYiYiXZqsWc",
      videoPath: "/learn/videos/organize-your-finances-adding-managing-accounts",
      videoDescription:
        "In this video, Adding accounts is simple! Just navigate to the Accounts section, and you can start creating and customizing accounts to reflect your financial reality.",
      videoThumbnail: AccountsThumbnail,
      videoBorderColor: "#E7DCCD",
    },
    {
      id: 4,
      videoTitle: "Managing your Budget Categories 🎯 | Setting Budget Allocations",
      videoUrl: "https://www.youtube.com/embed/ikpNktt5-V8?si=eeqe3vqyioylsP_c",
      videoPath: "/learn/videos/managing-your-budget-categories-setting-budget-allocations",
      videoDescription:
        "In Best Budget, your budget is your financial roadmap. 📊💡 It helps you organize and track your spending, ensuring you stay on top of your financial goals.",
      videoThumbnail: BudgetThumbnail,
      videoBorderColor: "#CDE7D0",
    },
    {
      id: 5,
      videoTitle: "Track & Manage Payments 💸 | Payees & Transactions Made Simple",
      videoUrl: "https://www.youtube.com/embed/1gklpEzow1w?si=QbIH7Ww64fEe70OP",
      videoPath: "/learn/videos/track-manage-payments-payees-transactions-made-simple",
      videoDescription:
        "Welcome to the Best Budget Payees & Transactions guide! 💰📊 In this video, we’ll show you how to set up and manage payees and transactions within your budget.",
      videoThumbnail: PayeesThumbnail,
      videoBorderColor: "#D9CDE7",
    },
    {
      id: 6,
      videoTitle: "Your Financial Overview 📊 | Dashboard & Reports Explained",
      videoUrl: "https://www.youtube.com/embed/rnnUaH93Ttw?si=l2UG6Z84-olnBDKH",
      videoPath: "/learn/videos/your-financial-overview-dashboard-reports-explained",
      videoDescription:
        "In this video, we'll take you through the Dashboard of Best Budget, where you’ll get a clear and concise overview of your finances.",
      videoThumbnail: DashboardThumbnail,
      videoBorderColor: "#E7CDCD",
    },
    {
      id: 7,
      videoTitle: "Manage Budgets📝 & Personalize Settings✨",
      videoUrl: "https://www.youtube.com/embed/XRpeI0dPhis?si=rRNrKA8HdeHwAhFx",
      videoPath: "/learn/videos/manage-budgets-and-personalize-settings",
      videoDescription:
        "In this video, we'll guide you through managing multiple budgets and setting up your profile preferences in Best Budget, making it easier than ever to stay in control of your finances.",
      videoThumbnail: ProfileSettingsThumbnail,
      videoBorderColor: "#CDD3E7",
    },
  ];

  return (
    <React.Fragment>
      <SEO
        title="Learn"
        description="we are excited to assist you in getting started and running smoothly with this innovative method for your money management!"
      />
      <div className="learn-main-container">
        <div className="learn-section-container">
          <div className="learn-section-container1-title">
            <p>Learn to Use Best Budget</p>
          </div>
          <div className="learn-section-toggle-button-container">
            <div
              className="learn-section-toggle-button-container-guides"
              onClick={() => handleToggle("Guides")}
            >
              <p className={active === "Guides" ? "active" : ""}>Guides</p>
            </div>
            <div
              className="learn-section-toggle-button-container-videos"
              onClick={() => handleToggle("Videos")}
            >
              <p className={active === "Videos" ? "active" : ""}>Videos</p>
            </div>
          </div>
        </div>
        {active === "Guides" ? (
          <>
            <div className="learn-section-container1">
              <div className="ln-section-container1-content-div">
                <div className="ln-section-container1-description">
                  <div className="ln-section-container1-description-title">
                    <p>Get Started with Best Budget</p>
                  </div>
                  <div className="ln-section-container1-description-text">
                    <p>
                      Congratulations! Being ready and willing to dive into your
                      finances with eyes wide open is at least half the battle.
                    </p>
                    <p>
                      Now we want to help you get up and running with this shiny
                      new method for managing your money!
                    </p>
                  </div>
                </div>
                <div className="ln-section-container1-img-div">
                  <div className="ln-section-container1-img">
                    <img
                      src={boyRunning}
                      alt="A boy eagerly picks up a frisbee, symbolizing his readiness to explore the features of the Best Budget personal finance application."
                    />
                  </div>
                  <div
                    className="ln-section-container1-learn-more-button"
                    onClick={() => {
                      handleNavigation({
                        module: "Get Started",
                        path: "/learn/learning-center/get-started-start-budgeting",
                      });
                    }}
                  >
                    <p>Learn More</p>
                    <div className="ln-section-container1-learn-more-button-img"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="learn-section-container2">
              <div className="ln-section-container-grid">
                <div className="ln-section-container2-grid1">
                  <div className="ln-section-container2-grid1-img">
                    <img src={AccountsIcon} alt="" />
                  </div>
                  <div className="ln-section-container2-grid1-title">
                    <p>Accounts</p>
                  </div>
                  <div className="ln-section-container2-grid1-description">
                    <p>
                      To begin managing your finances effectively, it's
                      essential to create accounts. Start by adding your various
                      financial assets.
                    </p>
                  </div>
                  <div className="ln-section-container2-grid1-description-learn-more">
                    <p
                      onClick={() => {
                        handleNavigation({
                          module: "Accounts",
                          path: "/learn/learning-center/accounts-add-account",
                        });
                        props.scrollToTop();
                      }}
                    >
                      Learn more
                    </p>
                  </div>
                </div>
                <div className="ln-section-container2-grid2">
                  <div className="ln-section-container2-grid2-img">
                    <img src={CategoriesIcon} alt="" />
                  </div>
                  <div className="ln-section-container2-grid2-title">
                    <p>Budget</p>
                  </div>
                  <div className="ln-section-container2-grid2-description">
                    <p>
                      Start budgeting effectively by creating categories, making
                      it easier to track your spending habits.
                    </p>
                  </div>
                  <div className="ln-section-container2-grid2-description-learn-more">
                    <p
                      onClick={() => {
                        handleNavigation({
                          module: "Budget",
                          path: "/learn/learning-center/budget-plan",
                        });
                        props.scrollToTop();
                      }}
                    >
                      Learn more
                    </p>
                  </div>
                </div>
                <div className="ln-section-container2-grid3">
                  <div className="ln-section-container2-grid3-img">
                    <img src={TransactionsIcon} alt="" />
                  </div>
                  <div className="ln-section-container2-grid3-title">
                    <p>Transactions</p>
                  </div>
                  <div className="ln-section-container2-grid3-description">
                    <p>
                      Record all your transactions accurately to make informed
                      decisions about your money, and organize your financial
                      activity.
                    </p>
                  </div>
                  <div className="ln-section-container2-grid3-description-learn-more">
                    <p
                      onClick={() => {
                        handleNavigation({
                          module: "Transactions",
                          path: "/learn/learning-center/transactions-add-income",
                        });
                        props.scrollToTop();
                      }}
                    >
                      Learn more
                    </p>
                  </div>
                </div>
                <div className="ln-section-container2-grid4">
                  <div className="ln-section-container2-grid4-img">
                    <img src={DashboardIcon} alt="" />
                  </div>
                  <div className="ln-section-container2-grid4-title">
                    <p>Dashboard</p>
                  </div>
                  <div className="ln-section-container2-grid4-description">
                    <p>
                      Gain insights into your financial health at a glance,
                      track spending trends, and monitor progress towards your
                      budgeting goals.
                    </p>
                  </div>
                  <div className="ln-section-container2-grid4-description-learn-more">
                    <p
                      onClick={() => {
                        handleNavigation({
                          module: "Dashboard",
                          path: "/learn/learning-center/dashboard-summary",
                        });
                        props.scrollToTop();
                      }}
                    >
                      Learn more
                    </p>
                  </div>
                </div>
                <div className="ln-section-container2-grid5">
                  <div className="ln-section-container2-grid5-img">
                    <img src={ReportsIcon} alt="" />
                  </div>
                  <div className="ln-section-container2-grid5-title">
                    <p>Reports</p>
                  </div>
                  <div className="ln-section-container2-grid5-description">
                    <p>
                      Explore Reports to uncover detailed analyses of your
                      financial activities, empowering you to make informed
                      decisions.
                    </p>
                  </div>
                  <div className="ln-section-container2-grid5-description-learn-more">
                    <p
                      onClick={() => {
                        handleNavigation({
                          module: "Reports",
                          path: "/learn/learning-center/reports-spending-analysis",
                        });
                        props.scrollToTop();
                      }}
                    >
                      Learn more
                    </p>
                  </div>
                </div>
                <div className="ln-section-container2-grid6">
                  <div className="ln-section-container2-grid6-img">
                    <img src={PayeesIcon} alt="" />
                  </div>
                  <div className="ln-section-container2-grid6-title">
                    <p>Payees</p>
                  </div>
                  <div className="ln-section-container2-grid6-description">
                    <p>
                      Explore how categorizing payees streamlines expense
                      tracking, empowering you to manage your finances more
                      effectively.
                    </p>
                  </div>
                  <div className="ln-section-container2-grid6-description-learn-more">
                    <p
                      onClick={() => {
                        handleNavigation({
                          module: "Payees",
                          path: "/learn/learning-center/payees-add-payee",
                        });
                        props.scrollToTop();
                      }}
                    >
                      Learn more
                    </p>
                  </div>
                </div>

                <div className="ln-section-container2-grid8">
                  <div className="ln-section-container2-grid8-img">
                    <img src={ProfileIcon} alt="" />
                  </div>
                  <div className="ln-section-container2-grid8-title">
                    <p>Profile</p>
                  </div>
                  <div className="ln-section-container2-grid8-description">
                    <p>
                      Manage your user personal information to reflect your
                      preferences and maintain control over your account details
                      effortlessly.
                    </p>
                  </div>
                  <div className="ln-section-container2-grid8-description-learn-more">
                    <p
                      onClick={() => {
                        handleNavigation({
                          module: "Profile",
                          path: "/learn/learning-center/profile-edit-profile-details",
                        });
                        props.scrollToTop();
                      }}
                    >
                      Learn more
                    </p>
                  </div>
                </div>
                <div className="ln-section-container2-grid9">
                  <div className="ln-section-container2-grid9-img">
                    <img src={OthersIcon} alt="" />
                  </div>
                  <div className="ln-section-container2-grid9-title">
                    <p>Others</p>
                  </div>
                  <div className="ln-section-container2-grid9-description">
                    <p>
                      This section covers additional features and customizations
                      in "Best Budget" that help you tailor the app to your
                      unique budgeting needs.
                    </p>
                  </div>
                  <div className="ln-section-container2-grid9-description-learn-more">
                    <p
                    // onClick={() => {
                    //   handleNavigation({
                    //     module: "Budget",
                    //     path: "/learn/learning-center/budget-add-category-group",
                    //   });
                    //   scrollToTop();
                    // }}
                    >
                      Learn more
                    </p>
                  </div>
                </div>
              </div>
              <div className="learn-go-to-top">
                <div
                  className="go-to-page-top"
                  onClick={() => {
                    props.scrollToTop();
                  }}
                ></div>
              </div>
            </div>
          </>
        ) : (
          <div className="learn-video-page-container1">
            <div className="learn-video-page-video-container-grid">
              {
                videosGridData.map((video) => (
                  <div className="learn-video-page-video-grid" key={video.id} onClick={() => {
                    navigate(video.videoPath)
                  }}>
                    <div className="learn-video-page-video-grid-row1">
                      <p>{video.id}</p>
                      <div className="learn-video-page-video-grid-border" style={{ background: video.videoBorderColor }}></div>
                    </div>
                    <div
                      className="learn-video-page-video-container-img-div"
                    >
                      <img src={video.videoThumbnail} alt={video.videoTitle} />
                    </div>
                    <div className="learn-video-page-video-container-title">
                      <p>{video.videoTitle}</p>
                    </div>
                    <div className="learn-video-page-video-container-description">
                      <p>{video.videoDescription}</p>
                    </div>
                  </div>
                ))
              }
            </div>
            <div className="learn-go-to-top">
              <div
                className="go-to-page-top"
                onClick={() => {
                  props.scrollToTop();
                }}
              ></div>
            </div>
          </div>
        )}
      </div>

      <Footer />
    </React.Fragment>
  );
};

export default Learn;
