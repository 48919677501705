import React from "react";
import backButton from "../../../Assets/Learn/Videos/backButton.svg";
import { useNavigate } from "react-router-dom";
import Footer from "../../Footer";
const ManagingBudgetCategories = () => {
    const navigate = useNavigate();
    const handleNavigation = () => {
        navigate(`/learn?tab=${"Videos"}`);
    };
    return (
        <React.Fragment>
            <div className="learn-main-container">
                <div className="learn-section-container">
                    <div className="learn-section-container1-title">
                        <p>Learn Videos</p>
                    </div>
                    <div
                        className="learn-section-container1-back-navigation-div"
                        onClick={() => {
                            handleNavigation();
                        }}
                    >
                        <img src={backButton} alt="back" />
                        <p>Back to Videos List</p>
                    </div>
                    <div className="learn-section-container1-video">
                        <iframe
                            src="https://www.youtube.com/embed/ikpNktt5-V8?si=aqdxkQfa0tH1pBZI"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullScreen="allowFullScreen"
                        ></iframe>
                    </div>
                    <div className="learn-section-container1-description">
                        <h3>
                            Managing your Budget Categories | Setting Budget Allocations
                        </h3>
                        <p>
                            Welcome to Best Budget!
                            <br />
                            Whether you're looking to take control of your finances, save for
                            a big goal, or simply track your spending, you're in the right
                            place.
                        </p>
                        <p>
                            In Best Budget, your budget is your financial roadmap. It helps
                            you organize and track your spending, ensuring you stay on top of
                            your financial goals. Whether you're planning for monthly
                            expenses, saving for future goals, or managing debt, your budget
                            serves as a guide for where and how you allocate your money.
                        </p>
                        <p>
                            To set up a budget, simply create a new budget plan, name it, and
                            customize your currency, number formats, and date settings
                            according to your preferences. Once your budget is set, you can
                            allocate funds across various categories like groceries,
                            utilities, or entertainment.
                        </p>
                        <p>
                            Best Budget allows you to update your budget anytime, making it
                            easy to adjust for changes in your income or expenses. You can
                            also track your spending in real-time, ensuring you never go over
                            budget and that you’re always moving closer to your financial
                            goals. With Best Budget, budgeting is not just about saving; it’s
                            about being in control of your financial future.
                        </p>
                        <p>
                            Download the app now from{" "}
                            <a
                                href="https://play.google.com/store/apps/details?id=com.crexin.bestbudget&hl=en"
                                target="_blank" rel="noreferrer"
                            >
                                Play Store
                            </a>{" "}
                            and take the first step toward smarter budgeting.
                            <br /> Need help? Email us at{" "}
                            <a href="mailto:support@bestbudget.in">
                                support@bestbudget.in
                            </a>{" "}
                            for assistance.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </React.Fragment>
    );
};

export default ManagingBudgetCategories;
