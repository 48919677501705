import React from "react";
import "./AddIncome.css";
import IncomeTrans1 from "../../../Assets/Learn/IncomeTrans1.webp";
import IncomeTrans2 from "../../../Assets/Learn/IncomeTrans2.webp";
import IncomeTrans3 from "../../../Assets/Learn/IncomeTrans3.webp";
import IncomeTrans4 from "../../../Assets/Learn/IncomeTrans4.webp";
import IncomeTrans5 from "../../../Assets/Learn/IncomeTrans5.webp";
import IncomeTrans6 from "../../../Assets/Learn/IncomeTrans6.webp";
import IncomeTrans7 from "../../../Assets/Learn/IncomeTrans7.webp";
import IncomeTrans8 from "../../../Assets/Learn/IncomeTrans8.webp";
import IncomeTrans9 from "../../../Assets/Learn/IncomeTrans9.webp";
import IncomeTrans10 from "../../../Assets/Learn/IncomeTrans10.webp";
import IncomeTrans11 from "../../../Assets/Learn/IncomeTrans11.webp";
import IncomeTrans12 from "../../../Assets/Learn/IncomeTrans12.webp";
import IncomeTrans13 from "../../../Assets/Learn/IncomeTrans13.webp";
import IncomeTrans14 from "../../../Assets/Learn/IncomeTrans14.webp";
import IncomeTrans15 from "../../../Assets/Learn/IncomeTrans15.webp";
import IncomeTrans16 from "../../../Assets/Learn/IncomeTrans16.webp";
import IncomeTrans17 from "../../../Assets/Learn/IncomeTrans17.webp";
import IncomeTrans18 from "../../../Assets/Learn/IncomeTrans18.webp";
import IncomeTrans19 from "../../../Assets/Learn/IncomeTrans19.webp";
import IncomeTrans20 from "../../../Assets/Learn/IncomeTrans20.webp";
const AddIncome = (props) => {
  return (
    <div className="add-income-main-container">
      <div className="add-income-breadcrumb">
        <p>Transactions</p>
        <p> &gt; </p>
        <p>Add Income</p>
      </div>
      <div className="add-income-title-div">
        <p>How to add an income transaction in Best Budget</p>
      </div>
      <div className="add-income-section-container1">
        <div className="add-income-section-container1-description">
          <ol>
            <li>
              Tap on the <span>'Transaction'</span> icon located in the
              navigation bar at the bottom of the screen. This will open the
              transaction creation page where you can add your new transaction.
            </li>
            <div className="add-income-section-container1-img1-div">
              <img src={IncomeTrans1} alt="" />
            </div>
            <li>
              By default, the <span>'Expense'</span> transaction type will be
              selected. If you wish to create an Income transaction, tap the{" "}
              <span>'Income'</span> button to switch from{" "}
              <span>Expense to Income</span>.
            </li>
            <div className="add-income-section-container1-img2-div">
              <img src={IncomeTrans2} alt="" />
            </div>
            <li>
              In the <span>Transaction Amount</span> field, input the amount of
              income you received or earned. This is the amount that will be
              recorded for this transaction.
            </li>
            <li>
              Choose the <span>Transaction Status</span> for the income
              transaction: <br /> <strong>Pending Status:</strong> indicates
              that the transaction is initiated but not cleared or received at
              the recipient bank account. <br />{" "}
              <strong>Cleared Status:</strong> indicates that the transaction is
              cleared and received at the recipient bank account.
            </li>
            <div className="add-income-section-container1-img2-div">
              <img src={IncomeTrans3} alt="" />
            </div>
            <li>
              Tap on the <span>'Payee'</span> field. Here, you can either select an existing
              payee from the list or create a new one. To create a new payee,
              tap on <span>'Add New Payee'</span> icon (represented by plus symbol) on top
              right corner and enter the necessary details (such as name or
              business). Refer{" "}
              <span
                onClick={() => {
                  props.handleNavigation({
                    path: "/learn/learning-center/payees-add-payee",
                    module: "Payees",
                  });
                }}
                style={{
                  color: "#2B24D8",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Payees
              </span>{" "}
              section to learn more about Payees.
            </li>
            <div className="add-income-section-container1-img2-div">
              <img src={IncomeTrans4} alt="" />
            </div>
            <div className="add-income-section-container1-img2-div">
              <img src={IncomeTrans5} alt="" />
            </div>
            <div className="add-income-section-container1-img2-div">
              <img src={IncomeTrans6} alt="" />
            </div>
            <li>
              If a default category has already been set up for the payee, the
              Category field will automatically be filled.
            </li>
            <li>
              {" "}
              To change the category or select one if a default isn’t set, Tap
              on the <span>'Category'</span> field and choose the relevant
              category for your income. Here, you can either select an existing
              category from the list or if you don’t find an appropriate
              category, tap on <span>'Add New category'</span> icon (represented
              by plus symbol) on top right corner and enter the necessary
              details.
            </li>
            <div className="add-income-section-container1-img2-div">
              <img src={IncomeTrans7} alt="" />
            </div>
            <div className="add-income-section-container1-img3-div">
              <img src={IncomeTrans8} alt="" />
            </div>
            <div className="add-income-section-container1-img2-div">
              <img src={IncomeTrans9} alt="" />
            </div>
            <li>
              Tap on the <span>Account</span> field and select the account where
              the income will be recorded.
            </li>
            <div className="add-income-section-container1-img2-div">
              <img src={IncomeTrans10} alt="" />
            </div>
            <div className="add-income-section-container1-img2-div">
              <img src={IncomeTrans11} alt="" />
            </div>
            <li>
              Tap on the <span>'Date'</span> field and choose the date for the
              transaction.
            </li>
            <div className="add-income-section-container1-img2-div">
              <img src={IncomeTrans12} alt="" />
            </div>
            <li>
              Tap on the <span>'Memo'</span> field to enter any notes about the transaction
              (optional). This could be a description or additional details that
              can help you remember the source or purpose of the transaction.
            </li>
            <div className="add-income-section-container1-img2-div">
              <img src={IncomeTrans13} alt="" />
            </div>
            <li>
              You have the flexibility to schedule transactions for future
              processing, allowing you to plan your finances in advance.
            </li>
            <div className="add-income-section-container1-img2-div">
              <img src={IncomeTrans14} alt="" />
            </div>
            <div className="add-income-section-container1-img2-div">
              <img src={IncomeTrans15} alt="" />
            </div>
            <li>
              You can also set reminders for your scheduled transactions. These
              reminders will notify you before the transaction is processed,
              ensuring you’re always aware of your upcoming financial
              activities.
              <br />
              <strong>Note:</strong> This field will appear only when you set
              recurring frequency.
            </li>
            <div className="add-income-section-container1-img2-div">
              <img src={IncomeTrans16} alt="" />
            </div>
            <div className="add-income-section-container1-img2-div">
              <img src={IncomeTrans17} alt="" />
            </div>
            <li>
              Once all the details have been filled out, tap the{" "}
              <span>'Save'</span> button located at the bottom of the screen to
              finalize and save the transaction.
            </li>
            <div className="add-income-section-container1-img3-div">
              <img src={IncomeTrans18} alt="" />
            </div>
            <li>
              When you save the income transaction, if the Category selected is
              other than <span>'Available to Budget'</span> category, then a
              suggestion promt is shown to assign the amount to <span>'Available to Budget'</span> for general use. However, you have the option to either
              follow this recommendation or assign the income directly to a
              specified category, based on your preference. This flexibility
              allows you to decide how best to allocate your funds.
            </li>
            <div className="add-income-section-container1-img3-div">
              <img src={IncomeTrans19} alt="" />
            </div>
            <div className="add-income-section-container1-img3-div">
              <img src={IncomeTrans20} alt="" />
            </div>
            <p>
              <strong>Note:</strong> When creating a new transaction, the app
              will automatically pre-fill the details from the previous
              transaction (if any) based on the transaction type (Income or
              Expense or Transfer) you are creating. You can edit these details
              as necessary before saving. This feature helps streamline the
              process when dealing with similar transactions.
            </p>
          </ol>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default AddIncome;
