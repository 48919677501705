import React from "react";
import "./HideCategory.css";
import AddCatGroup1 from "../../../Assets/Learn/AddCatGroup1.webp";
import HideCategory2 from "../../../Assets/Learn/HideCategory2.webp";
import HideCategory3 from "../../../Assets/Learn/HideCategory3.webp";
import HideCategory5 from "../../../Assets/Learn/HideCategory5.webp";
import HideCategory6 from "../../../Assets/Learn/HideCategory6.webp";
import HideCategory7 from "../../../Assets/Learn/HideCategory7.webp";
import HideCategory8 from "../../../Assets/Learn/HideCategory8.webp";
const HideCategory = (props) => {
  return (
    <div className="hide-category-main-container">
      <div className="hide-category-breadcrumb">
        <p>Budget</p>
        <p> &gt; </p>
        <p>Hide Category</p>
      </div>
      <div className="hide-category-title-div">
        <p>How to hide a category in Best Budget</p>
      </div>
      <div className="hide-category-section-container1">
        <div className="hide-category-section-container1-description">
          <ol>
            <li>
              Tap on the <span>'Manage Categories'</span> icon (represented by a pencil
              shape) located in the top right corner of the screen. This will
              direct you to the category management section.
            </li>
            <div className="hide-category-section-container1-img1-div">
              <img src={AddCatGroup1} alt="" />
            </div>
            <li>Locate the desired category group you wish to Hide.</li>
            <li>
              Look for the <span>'menu'</span> icon (represented by minus symbol) positioned
              near the left side of the category group name.{" "}
            </li>
            <li>
              From the menu that appears, select the <span>'Hide'</span> option. This action
              will remove the selected category group from your visible list,
              effectively hiding it from your budget management view.
            </li>
            <div className="hide-category-section-container1-img1-div">
              <img src={HideCategory2} alt="" />
            </div>
            <div className="hide-category-section-container1-img1-div">
              <img src={HideCategory3} alt="" />
            </div>
          </ol>
        </div>
      </div>
      <div className="hide-category-title-div">
        <p>How to show a hidden category in Best Budget</p>
      </div>
      <div className="hide-category-section-container1">
        <div className="hide-category-section-container1-description">
          <ol>
            <li>
              Open Budget page and tap on <span>“Manage Categories”</span> icon located on
              the top right corner.
            </li>
            <div className="hide-category-section-container1-img1-div">
              <img src={AddCatGroup1} alt="" />
            </div>
            <li>
              Tap on the <span>'Hidden Categories'</span> button located at the bottom of the
              screen.
            </li>

            <div className="hide-category-section-container1-img1-div">
              <img src={HideCategory5} alt="" />
            </div>
            <li>Find the desired category you wish to show.</li>
            <li>Tap on the <span>'Show'</span> icon associated with the desired category to
              unhide it.
            </li>
            <li> Alternatively, you can tap on the <span>'Show All'</span> button located at
              the top right of the screen, to unhide all hidden category groups
              and categories at once.
            </li>
            <div className="hide-category-section-container1-img1-div">
              <img src={HideCategory6} alt="" />
            </div>
            <li>A pop-up window will appear; tap on the <span>'Show'/'Show All'</span> button to unhide.</li>
            <div className="hide-category-section-container1-img1-div">
              <img src={HideCategory7} alt="" />
            </div>
            <div className="hide-category-section-container1-img1-div">
              <img src={HideCategory8} alt="" />
            </div>
          </ol>
        </div>
      </div>

      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default HideCategory;
