import React from "react";
import "./SpendAnalysis.css";
import SpendingAnalysis1 from "../../../Assets/Learn/SpendingAnalysis1.webp";
import SpendingAnalysis2 from "../../../Assets/Learn/SpendingAnalysis2.webp";
import SpendingAnalysis3 from "../../../Assets/Learn/SpendingAnalysis3.webp";
import SpendingAnalysis4 from "../../../Assets/Learn/SpendingAnalysis4.webp";
import SpendingAnalysis5 from "../../../Assets/Learn/SpendingAnalysis5.webp";
import SpendingAnalysis6 from "../../../Assets/Learn/SpendingAnalysis6.webp";
import SpendingAnalysis7 from "../../../Assets/Learn/SpendingAnalysis7.webp";
import SpendingAnalysis8 from "../../../Assets/Learn/SpendingAnalysis8.webp";
import SpendingAnalysis9 from "../../../Assets/Learn/SpendingAnalysis9.webp";
import SpendingAnalysis10 from "../../../Assets/Learn/SpendingAnalysis10.webp";
import SpendingAnalysis11 from "../../../Assets/Learn/SpendingAnalysis11.webp";
import SpendingAnalysis12 from "../../../Assets/Learn/SpendingAnalysis12.webp";
const SpendAnalysis = (props) => {
  return (
    <div className="spend-analysis-main-container">
      <div className="spend-analysis-breadcrumb">
        <p>Reports</p>
        <p> &gt; </p>
        <p>Spending Analysis</p>
      </div>
      <div className="spend-analysis-title-div">
        <p>Spending Analysis report in Best Budget</p>
      </div>
      <div className="spend-analysis-section-container1">
        <div className="spend-analysis-section-container1-description">
          <ol>
            <li>
              Tap on the <span>'More'</span> icon in the navigation bar and select the
              <span> 'Reports'</span> option.
            </li>
            <div className="spend-analysis-section-container1-img1-div">
              <img src={SpendingAnalysis1} alt="" />
            </div>
            <div className="spend-analysis-section-container1-img1-div">
              <img src={SpendingAnalysis2} alt="" />
            </div>
            <li>
              By default, the Spending Analysis tab opens up and this section
              provides a detailed breakdown of your spending habits across
              categories groups.
            </li>
            <li>
              Tap on the desired category group to view the spending analysis
              for categories associated with that particular category group.
            </li>
            <div className="spend-analysis-section-container1-img2-div">
              <img src={SpendingAnalysis3} alt="" />
            </div>
            <li>
              To view the analysis for the next category group, tap the Next
              icon. You can switch between category groups using the left and
              right arrows.
            </li>
            <div className="spend-analysis-section-container1-img4-div">
              <img src={SpendingAnalysis4} alt="" />
            </div>
            <li>
              To return to the view showing all category groups, tap the <span>'Back' </span>
              icon.
            </li>
            <div className="spend-analysis-section-container1-img4-div">
              <img src={SpendingAnalysis5} alt="" />
            </div>
          </ol>
        </div>
      </div>
      <div className="spend-analysis-title-div">
        <p>Filters in Category Groups</p>
      </div>
      <div className="spend-analysis-section-container1">
        <div className="spend-analysis-section-container1-description">
          <ol>
            <li>
              To open filters for all categories in all category groups, tap on
              the 'filters' icon (represented by 3 vertical lines) located at
              the top right corner .
            </li>
            <div className="spend-analysis-section-container1-img4-div">
              <img src={SpendingAnalysis6} alt="" />
            </div>
            <li>
              By default, <span>'Accounts'</span> tab is opened with <span>'Select All'</span> filters.
              Tap on <span>'Select None'</span> to unselect all accounts or uncheck specific
              accounts as needed.
            </li>
            <div className="spend-analysis-section-container1-img4-div">
              <img src={SpendingAnalysis7} alt="" />
            </div>
            <li>
              Switch to <span>'Category groups'</span> tab which will display all category
              groups with <span>'Select All'</span> filters enabled. Tap on the <span>'Select None' </span>
              to unselect all category groups, or uncheck specific groups as
              needed.
            </li>
            <div className="spend-analysis-section-container1-img4-div">
              <img src={SpendingAnalysis8} alt="" />
            </div>
            <li>
              Switch to <span>'Time frame'</span> tab to modify the date filters. By default,
              <span> 'Current Month'</span> option is selected. If you need to view data for a
              different time period, select another date filter.
            </li>
            <li>
              If Date Range is selected, tap on the <span>'Start date'</span> to choose the
              beginning date, and then tap on the <span>'End date'</span> to select the
              ending date to customize the range for the spending analysis.
            </li>
            <li>
              If you are satisfied with the selected filters, tap the <span>'Apply' </span>
              button to activate them and refresh the data accordingly.
            </li>
            <li>To
              clear all selected filters and reset to the default settings, tap
              the <span>'Reset'</span> button.</li>
            <div className="spend-analysis-section-container1-img2-div">
              <img src={SpendingAnalysis9} alt="" />
            </div>
          </ol>
        </div>
      </div>
      <div className="spend-analysis-title-div">
        <p>Filters in Categories</p>
      </div>
      <div className="spend-analysis-section-container1">
        <div className="spend-analysis-section-container1-description">
          <ol>
            <li>
              To open filters for all categories in a specific category group,
              tap on the category group name to view its categories, then tap on
              the <span>'filters'</span> icon (represented by 3 horizontal lines) at the top
              right corner..
            </li>
            <div className="spend-analysis-section-container1-img4-div">
              <img src={SpendingAnalysis10} alt="" />
            </div>
            <li>By default, <span>'Accounts'</span> is opened with <span>'Select All'</span> filters.</li>
            <li>
              Tap on <span>'Categories'</span> or <span>'Time frame'</span> to move to their respective
              filters.
            </li>
            <li>
              Follow the same process as <span>category</span> filters to customize your
              selections.
            </li>
            <div className="spend-analysis-section-container1-img4-div">
              <img src={SpendingAnalysis11} alt="" />
            </div>
            <li>
              If you are satisfied with the selected filters, tap the <span>'Apply'</span> button to activate them and refresh the data accordingly.
            </li>
            <li>
              To clear all selected filters and reset to the default settings, tap the <span>'Reset'</span> button.
            </li>
            <div className="spend-analysis-section-container1-img3-div">
              <img src={SpendingAnalysis12} alt="" />
            </div>
          </ol>
        </div>
      </div>
      <div className="learn-go-to-top">
        <div
          className="go-to-page-top"
          onClick={() => {
            props.scrollToTop();
          }}
        ></div>
      </div>
    </div>
  );
};

export default SpendAnalysis;
