import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { MenuContext } from '../MenuContext';
import "../App.css";
import bestBudgetIcon from "../Assets/bestBudgetIcon.svg";
import toggleButton from "../Assets/LandingWebsite/toggleButton.svg";
import toggleCloseButton from "../Assets/LandingWebsite/toggleCloseButton.svg";
import currentPageIndicator from "../Assets/currentPageIndicator.svg";
const Header = () => {
  const { isMenuOpen, toggleMenu } = useContext(MenuContext);
  const navigate = useNavigate();
  const toggleNavbarMenu = () => {
    toggleMenu(!isMenuOpen);
  };

  return (
    <div className="head">
      <div className="header-container">
        <div className={window.location.pathname === "/blog" ? "no-borders navbar" : "borders navbar"}>
          <div className="navbar-container">
            <div className="title-logo">
              <img alt="" src={bestBudgetIcon} />
              <p
                className="navbar-brand"
                onClick={() => {
                  navigate("/");
                }}
              >
                BEST BUDGET
              </p>
            </div>
            <div
              className={`page page1 ${window.location.pathname === "/why-best-budget"
                ? "active-page"
                : ""
                }`}
            >
              <p
                onClick={() => {
                  navigate("/why-best-budget");
                }}
              >
                Why Best Budget
              </p>

              <img alt=""
                src={currentPageIndicator}
                className={
                  window.location.pathname === "/why-best-budget"
                    ? "active-page-indicator"
                    : "img"
                }

              />
            </div>
            <div
              className={`page page2 ${window.location.pathname === "/pricing" ? "active-page" : ""
                }`}
            >
              <p
                onClick={() => {
                  navigate("/pricing");
                }}
              >
                Pricing
              </p>

              <img alt=""
                src={currentPageIndicator}
                className={
                  window.location.pathname === "/pricing"
                    ? "active-page-indicator"
                    : "img"
                }

              />
            </div>
            <div
              className={`page page3 ${window.location.pathname === "/learn" ? "active-page" : ""
                }`}
            >
              <p
                onClick={() => {
                  navigate("/learn");
                }}
              >
                Learn
              </p>
              <img alt=""
                src={currentPageIndicator}
                className={
                  window.location.pathname === "/learn"
                    ? "active-page-indicator"
                    : "img"
                }

              />
            </div>
            <div
              className={`page page4 ${window.location.pathname === "/abount-us" ? "active-page" : ""
                }`}
            >
              <p
                onClick={() => {
                  navigate("/about-us");
                }}
              >
                About Us
              </p>
              <img alt=""
                src={currentPageIndicator}
                className={
                  window.location.pathname === "/about-us"
                    ? "active-page-indicator"
                    : "img"
                }

              />
            </div>
            <div
              className={`page page5 ${window.location.pathname === "/blog" ? "active-page" : ""
                }`}
            >
              <p
                onClick={() => {
                  navigate("/blog");
                }}
              >
                Blog
              </p>
              <img alt=""
                src={currentPageIndicator}
                className={
                  window.location.pathname === "/blog"
                    ? "active-page-indicator"
                    : "img"
                }

              />
            </div>
          </div>
          <div className="navbar-mobile-container">
            <div className="best-budget-div">

              <div className="navbar-brand" onClick={() => {
                navigate("/");
                if (isMenuOpen === true) {
                  toggleNavbarMenu();
                }
              }} >

              </div>
            </div>
            <div
              className="toggle-button-div"
              onClick={() => {
                toggleNavbarMenu();
              }}
            >
              {!isMenuOpen ? (
                <img alt="" src={toggleButton} />
              ) : (
                <img alt="" src={toggleCloseButton} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-nav">
        {isMenuOpen ? (
          <div className="mobile-nav-menu-div">
            <p
              className="nav-item"
              onClick={() => {
                navigate("/why-best-budget");
                toggleMenu();
              }}
            >
              Why Best Budget
            </p>
            <p
              className="nav-item"
              onClick={() => {
                navigate("/pricing");
                toggleMenu();
              }}
            >
              Pricing
            </p>
            <p
              className="nav-item"
              onClick={() => {
                navigate("/learn");
                toggleMenu();
              }}
            >
              Learn
            </p>
            <p
              className="nav-item"
              onClick={() => {
                navigate("/about-us");
                toggleMenu();
              }}
            >
              About Us
            </p>
            <p
              className="nav-item"
              onClick={() => {
                navigate("/blog");
                toggleMenu();
              }}
            >
              Blog
            </p>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default Header;
