import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import budgetingImage from "../../Assets/Blog/budgeting.webp";
import InvestingImage from "../../Assets/Blog/investing.webp";
import savingsImage from "../../Assets/Blog/savings.webp";
import adImage from "../../Assets/Blog/adImage.png";
import financialPlanning from "../../Assets/Blog/financialPlanning.webp";
import Footer from "../Footer";
import "./SavingBlog.css";
// import { useState } from "react";
const SavingBlog = (props) => {
  const navigate = useNavigate();
  const [activeSubheading, setActiveSubheading] = useState(null);
  const postContentRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const subheadings = document.querySelectorAll(".post-content h2"); // Adjust for your specific heading level (h2, h3, etc.)

      subheadings.forEach((subheading) => {
        const section = document.getElementById(subheading.id);
        const rect = section.getBoundingClientRect();

        // Adjust the threshold as needed; 0 means when it's at the top of the viewport
        if (rect.top <= 50 && rect.bottom >= 0) {
          setActiveSubheading(subheading.id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <React.Fragment>
      <div className="blog4-main-container">
        <div className="blog4-section1-container">
          <div className="blog4-main-image-container">
            <img src={savingsImage} alt="financial planning" />
          </div>
          <div className="blog4-main-content">
            <h1 className="blog4-main-title">
              Effective Tips for Saving Money Quickly and Easily
            </h1>
            <div className="blog4-main-desc-text">
              <p>
                Living paycheck to paycheck? Feeling the pinch of inflation?
                Don't worry, you're not alone. But fret not, fellow budget
                warriors! Here's a treasure trove of practical tips to help you
                save money quickly and easily, putting you back in control of
                your finances.
              </p>
            </div>
            <div className="blog4-main-description-container">
              <div className="blog4-main-sidebar">
                <ul>
                  <li
                    className={
                      activeSubheading === "subheading1" ? "active" : ""
                    }
                  >
                    <a href="#subheading1">Embrace the Power of Awareness</a>
                  </li>
                  <li
                    className={
                      activeSubheading === "subheading2" ? "active" : ""
                    }
                  >
                    <a href="#subheading2">Slash Unnecessary Expenses</a>
                  </li>
                  <li
                    className={
                      activeSubheading === "subheading3" ? "active" : ""
                    }
                  >
                    <a href="#subheading3">Become a Savvy Shopper</a>
                  </li>
                  <li
                    className={
                      activeSubheading === "subheading4" ? "active" : ""
                    }
                  >
                    <a href="#subheading4">Boost Your Income Stream</a>
                  </li>
                  <li
                    className={
                      activeSubheading === "subheading5" ? "active" : ""
                    }
                  >
                    <a href="#subheading5">Automate Your Savings</a>
                  </li>
                  <li
                    className={
                      activeSubheading === "subheading6" ? "active" : ""
                    }
                  >
                    <a href="#subheading6">Conclusion</a>
                  </li>
                </ul>
              </div>
              <div className="blog4-main-desc-content">
                <div
                  className="blog4-main-desc-text2 post-content"
                  ref={postContentRef}
                >
                  <h2 id="subheading1">Embrace the Power of Awareness </h2>
                  <p>
                    The first step to saving is understanding where your money
                    goes. Track your expenses for a month – every penny counts.
                    There are several ways to do this:
                  </p>
                  <ul>
                    <li>
                      <strong>Pen and paper</strong>: Old-school but effective.
                      Jot down every expense for a month.
                    </li>
                    <li>
                      <strong>Spreadsheets</strong>: Create a simple spreadsheet
                      to categorize and analyze your spending.
                    </li>
                    <li>
                      <strong>Budgeting apps</strong>: Many apps offer automatic
                      transaction tracking and insightful visualizations.
                    </li>
                  </ul>
                  <p>
                    Once you see where your money flows, you can identify areas
                    to cut back.
                  </p>
                </div>
                <div
                  className="blog4-main-desc-text3 post-content"
                  ref={postContentRef}
                >
                  <h2 id="subheading2">Slash Unnecessary Expenses</h2>
                  <p>Here are some strategies to free up cash:</p>
                  <ul>
                    <li>
                      <strong>Unsubscribe from unused subscriptions</strong>:
                      Gym memberships you never use? Streaming services
                      collecting dust? Cancel them!
                    </li>
                    <li>
                      <strong>Embrace free entertainment</strong>: Explore free
                      alternatives – parks, libraries, museums with free
                      admission days.
                    </li>
                    <li>
                      <strong>Cook more at home</strong>: Eating out expenses
                      can skyrocket. Explore delicious and budget-friendly
                      recipes.
                    </li>
                    <li>
                      <strong>Renegotiate bills</strong>: Don't be afraid to
                      call your service providers (internet, cable) and
                      negotiate a better rate
                    </li>
                  </ul>
                </div>
                <div
                  className="blog4-main-desc-text4 post-content"
                  ref={postContentRef}
                >
                  <h2 id="subheading3">Become a Savvy Shopper</h2>
                  <p>
                    Planning your purchases and utilizing smart shopping
                    strategies can yield significant savings:
                  </p>
                  <ul>
                    <li>
                      <strong>Create a grocery list</strong>: Stick to it to
                      avoid impulse buys at the store.
                    </li>
                    <li>
                      <strong>Embrace generic brands</strong>: Often,
                      store-brand quality matches national brands at a fraction
                      of the cost.
                    </li>
                    <li>
                      <strong>Utilize coupons and discounts</strong>: Look for
                      coupons online or in weekly flyers. Consider cashback apps
                      for additional savings.
                    </li>
                    <li>
                      <strong>Embrace the power of "No"</strong>: Don't fall
                      prey to marketing tactics. Learn to resist impulse buys.
                    </li>
                  </ul>
                </div>
                <div
                  className="blog4-main-desc-text5 post-content"
                  ref={postContentRef}
                >
                  <h2 id="subheading4">Boost Your Income Stream</h2>
                  <p>
                    While cutting back is important, consider increasing your
                    income for a more sustainable approach.
                  </p>
                  <ul>
                    <li>
                      <strong>Freelance work</strong>: Offer your skills on
                      freelance platforms for extra income.
                    </li>
                    <li>
                      <strong>Sell unused items</strong>:Declutter your home and
                      turn unwanted items into cash through online marketplaces
                      or garage sales.
                    </li>
                    <li>
                      <strong>Side hustle</strong>: Explore part-time jobs,
                      online gigs, or hobbies that can generate additional
                      income.
                    </li>
                  </ul>
                  <h2 id="subheading5">Automate Your Savings</h2>
                  <p>Set it and forget it! Here's how to automate savings:</p>
                  <ul>
                    <li>
                      <strong>Schedule automatic transfers</strong>:Set up
                      automatic transfers from your checking account to your
                      savings account every payday.
                    </li>
                    <li>
                      <strong>Round-up apps</strong>:Some apps round up your
                      purchases to the nearest dollar and deposit the difference
                      in your savings.
                    </li>
                  </ul>
                </div>
                <div
                  className="blog4-main-desc-text6 post-content"
                  ref={postContentRef}
                >
                  <h2 id="subheading6">Conclusion</h2>
                  <p>
                    Saving money is a marathon, not a sprint. Celebrate every
                    milestone, no matter how small. This will keep you motivated
                    on your saving journey.
                  </p>
                  <p>
                    aving money is a continuous journey. By implementing these
                    tips, tracking your progress, and constantly looking for
                    ways to optimize your spending, you'll be surprised how
                    quickly your savings can grow. Take control of your
                    finances, save strategically, and watch your financial
                    future prosper!
                  </p>
                </div>
              </div>
              <div className="blog4-main-right-sidebar">
                <div className="blog4-main-right-sidebar-section1">
                  <h4>Try Best Budget</h4>
                  <p>
                    Best Budget is the best app for tracking daily expenses,
                    create monthly budget planner, monitor investments, and much
                    more.
                  </p>
                  <button
                    type="button"
                    onClick={() => {
                      props.openExternalLink(
                        "https://play.google.com/store/apps/details?id=com.crexin.bestbudget&hl=en"
                      );
                    }}
                    className="blog4-main-right-sidebar-button"
                  >
                    Start Free Trial
                  </button>
                </div>
                <div className="blog4-main-right-sidebar-section2">
                  <p>Also checkout these latest blogs</p>
                  <div className="blog4-right-sidebar-related-topics-routes">
                    <p
                      onClick={() => {
                        navigate(
                          "/blog/need-to-achieve-good-outcome-from-financial-planning"
                        );
                        scrollToTop();
                      }}
                    >
                      Need to achieve good outcome from Financial Planning ?
                    </p>
                    <p
                      onClick={() => {
                        navigate(
                          "/blog/how-budgeting-drives-financial-success-and-management"
                        );
                        scrollToTop();
                      }}
                    >
                      How Budgeting Drives Financial Success and Management
                    </p>
                    <p
                      onClick={() => {
                        navigate(
                          "/blog/top-tips-for-tracking-all-your-investments-in-one-place"
                        );
                        scrollToTop();
                      }}
                    >
                      Top Tips for Tracking All Your Investments in One Place
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default SavingBlog;
